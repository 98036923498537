const SalaryConstant = {

    UPLOAD_SALARY_FILE: 'UPLOAD_SALARY_FILE',
    GET_SALARY_TEMPLATE: 'GET_SALARY_TEMPLATE',
    UPLOAD_DEPOSIT_SLIP: 'UPLOAD_DEPOSIT_SLIP',

    GET_CHARGES: 'GET_CHARGES',

    BROWSE_UPLOAD: 'BROWSE_UPLOAD',

    GET_LINKED_SALARIES: 'GET_LINKED_SALARIES',

};

export default SalaryConstant;
