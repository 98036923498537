import React from 'react';
import { Skeleton } from 'antd';

const CSkeletonInput = ({ active = true, size = 'small', style = { width: 120, minWidth: 120 } }) => {
    return (
        <Skeleton.Input size={size} active={active} style={style} />
    )
};

export default CSkeletonInput
