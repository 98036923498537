const CentivCardConstant = {
  GET_CARD_TRANSACTION_DATA: "GET_CARD_TRANSACTION_DATA",
  GET_CENTIV_TABLE: "GET_CENTIV_TABLE",
  GET_CENTIV_BALANCE: "GET_CENTIV_BALANCE",
  GET_OVERALL_DATA: "GET_OVERALL_DATA",
  LOAD_FUNDS: "LOAD_FUNDS",
  GET_STATEMEMNT_FILE: "GET_STATEMEMNT_FILE",
  SYNC_TRANSACTIONS: "SYNC_TRANSACTIONS",
};

export default CentivCardConstant;
