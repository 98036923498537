import { post, get } from "../../utils/apiMethods";
import Auth from "../constants/Auth.constant";
import {
  clearLocalstorage,
  getValueIntoLocalStorage,
  setValueIntoHeaders,
  setValueInToLocalStorage,
} from "../../utils/asyncStorage/Functions";
import { getTokenAndSetIntoHeaders } from "../../utils/intercepter";
import { handleError, handleSuccess } from "../../utils/methods";
import { BRANCH_ID, TOKEN } from "../../utils/asyncStorage/Constants";
import { refreshTokenTimer } from "./Common.action";

export const signUp = (values, CB) => (dispatch) => {
  dispatch({ type: Auth.SIGNUP_USER_API, loading: true });
  post(`exchangeHouse/create`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: Auth.SIGNUP_USER_API, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB({ email: values?.email, client: values?.companyName });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.SIGNUP_USER_API, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: Auth.SIGNUP_USER_API, loading: false });
    });
};

export const login = (payload, CB, isBranch) => async (dispatch) => {
  dispatch({ type: Auth.LOGIN_USER_API, loading: true, isLoggedIn: false });
  post(`exchangeHouse/login`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        if (data?.data?.token) {
          await setValueInToLocalStorage(TOKEN, data.data.token);
          await getTokenAndSetIntoHeaders(data.data.token);
          handleSuccess("Successfully login");
          dispatch(getProfile());
          dispatch({
            type: Auth.LOGIN_USER_API,
            loading: false,
            isLoggedIn: true,
          });
          window.location.reload();
        } else {
          dispatch({
            type: Auth.LOGIN_USER_API,
            loading: false,
            isLoggedIn: false,
          });
        }
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({
          type: Auth.LOGIN_USER_API,
          loading: false,
          isLoggedIn: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: Auth.LOGIN_USER_API,
        loading: false,
        isLoggedIn: false,
      });
      handleError(error?.data?.message || error?.message);
    });
};

// export const login = (payload, CB) => (dispatch) => {
//   dispatch({ type: Auth.LOGIN_USER_API, loading: true });
//   post(`generic/login`, payload)
//     .then(({ data }) => {
//       if (!data.error) {
//         if (data?.data?.token) {
//           dispatch({ type: Auth.LOGIN_USER_API, loading: false });
//           handleSuccess(data?.data?.message  ||  'OTP Generated !');
//           CB && CB({ token: data.data.token, email: data?.data?.email, phoneNumber: data?.data?.phoneNumber, twoFactorEnable: data?.data?.twoFactorEnable });
//         } else {
//           dispatch({ type: Auth.LOGIN_USER_API, loading: false, isLoggedIn: false });
//         }
//       } else {
//         handleError(data?.data?.message || "Something went wrong!");
//         dispatch({ type: Auth.LOGIN_USER_API, loading: false, isLoggedIn: false });
//       }
//     })
//     .catch((error) => {
//       dispatch({ type: Auth.LOGIN_USER_API, loading: false, isLoggedIn: false, });
//       handleError(error?.data?.message || error?.message);
//     });
// };

export const setTwoFactorAuthentication = (payload, CB) => (dispatch) => {
  dispatch({ type: Auth.SET_OTP_AUTH, loading: true });
  post(`generic/setTwoFactorAuthentication`, payload)
    .then(({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        CB && CB();
        dispatch({ type: Auth.SET_OTP_AUTH, loading: false });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.SET_OTP_AUTH, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.SET_OTP_AUTH, loading: false, isLoggedIn: false });
      handleError(error?.data?.message || error?.message);
    });
};


export const loginVerifyOtp = (payload, CB) => async (dispatch) => {
  dispatch({ type: Auth.GEN_USER_OTP, loading: true, isLoggedIn: false });
  post(`generic/verifyOTP`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        if (data?.data?.token) {
          await setValueInToLocalStorage(TOKEN, data?.data?.token);
          await getTokenAndSetIntoHeaders(data?.data?.token);
          dispatch({ type: Auth.GEN_USER_OTP, loading: false, isLoggedIn: true });
          handleSuccess(data?.data?.message);
          window.location.reload();
        }
      }
      dispatch({ type: Auth.GEN_USER_OTP, loading: false, isLoggedIn: false });
    })
    .catch((error) => {
      dispatch({ type: Auth.GEN_USER_OTP, loading: false, isLoggedIn: false });
      handleError(error?.data?.message || error?.message);
    });
};

export const resendOTP = (payload, CB) => async (dispatch) => {
  dispatch({ type: Auth.RESEND_OTP_API, loading: true });
  post(`generic/resendOTP`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message || 'OTP re-generate successfully!')
        dispatch({ type: Auth.RESEND_OTP_API, loading: false });
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.RESEND_OTP_API, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.RESEND_OTP_API, loading: false });
      handleError(error?.data?.message || error?.message);
    });
};


export const selectBranch = (payload) => async (dispatch) => {
  setValueIntoHeaders(payload?.branch, "exchangeHouseBranch");
  dispatch({
    type: Auth.GEN_USER_OTP,
    loading: false,
    isLoggedIn: true,
    selectedBranch: payload.branch,
  });
  await setValueInToLocalStorage(TOKEN, payload.token);
  // await setValueInToLocalStorage(BRANCH_ID, payload.branch);
  await getTokenAndSetIntoHeaders(payload.token);
  dispatch(getProfile());
};

export const getProfile = (CB) => async (dispatch) => {
  let token = await getValueIntoLocalStorage(TOKEN);
  if (token) {
    dispatch({ type: Auth.GET_USER_PROFILE, loading: true, isLoggedIn: true });
    get(`exchangeHouse/getProfile`)
      .then(async ({ data }) => {
        if (!data.error) {
          dispatch({
            type: Auth.GET_USER_PROFILE,
            loading: false,
            user: data?.data,
            isLoggedIn: true,
          });
          CB && CB();
        } else {
          handleError(data?.data?.message || "Something went wrong!");
          dispatch({
            type: Auth.GET_USER_PROFILE,
            loading: false,
            isLoggedIn: true,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: Auth.GET_USER_PROFILE,
          loading: false,
          isLoggedIn: false,
        });
        handleError(error?.data?.message || error?.message);
      });
  } else {
    dispatch(logout());
  }
};

export const forgetPassword = (values, CB) => (dispatch) => {
  dispatch({ type: Auth.FORGET_API, loading: true });
  post(`exchangeHouse/forgetPassword`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        dispatch({ type: Auth.FORGET_API, loading: false });
        CB && CB(data?.data);
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: Auth.FORGET_API, loading: false });
    });
};

export const resetPassword = (values, CB) => (dispatch) => {
  dispatch({ type: Auth.RESET_PASSWORD_API, loading: true });
  post(`exchangeHouse/resetPassword`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        dispatch({
          type: Auth.RESET_PASSWORD_API,
          loading: false,
          isLoggedIn: false,
          user: {},
        });
        CB && CB({ email: values?.email });
      } else {
        dispatch({ type: Auth.RESET_PASSWORD_API, loading: false });

        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: Auth.RESET_PASSWORD_API, loading: false });
    });
};

export const updateEchangeHouse = (values, CB) => (dispatch) => {
  dispatch({ type: Auth.CHANGE_PASSWORD, loading: true });
  post(`exchangeHouse/update`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: Auth.CHANGE_PASSWORD, loading: false });

        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message);
      }
    })

    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: Auth.CHANGE_PASSWORD, loading: false });
    });
};
export const toggleAuth = (user) => async (dispatch) => {
  dispatch({ type: Auth.TOGGLE_AUTH, isLoggedIn: true, user: user });
};

export const verifyOTP = (payload, CB, type) => async (dispatch) => {
  dispatch({ type: Auth.CHECK_OTP, loading: true, isLoggedIn: false });
  post(`exchangeHouse/verifyResetCode`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        if (type === "signup" && data?.data?.token) {
          await setValueInToLocalStorage(TOKEN, data.data.token);
          await getTokenAndSetIntoHeaders(data.data.token);
          handleSuccess("Successfully login");
          dispatch(getProfile());
          dispatch({
            type: Auth.CHECK_OTP,
            loading: false,
            isLoggedIn: true,
            user: data?.data?.data,
          });
        } else if (type === "forgot" || !data?.data?.token) {
          dispatch({
            type: Auth.CHECK_OTP,
            loading: false,
            isLoggedIn: false,
            user: {},
          });
          CB &&
            CB(data?.data);
        }
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.CHECK_OTP, loading: false, isLoggedIn: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.CHECK_OTP, loading: false, isLoggedIn: false });
      handleError(error?.data?.message || error?.message);
    });
};

export const updateProfile = (payload, CB) => (dispatch) => {
  let dispatchType = Auth.UPDATE_PROFILE;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/updateProfile`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || "Success");
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.message || error?.data?.message);
    });
};

export const updateClientDocuments = (payload, CB) => (dispatch) => {
  let dispatchType = Auth.UPDATE_DOCS;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/updateClientDocuments`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || "Success");
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.message || error?.data?.message);
    });
};

export const getTransaction = (payload) => async (dispatch) => {
  dispatch({ type: Auth.GET_TRANSACTION, loading: true });
  post(`employer/employerTransaction`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: Auth.GET_TRANSACTION,
          loading: false,
          data: data?.data?.data?.data,
          metaData: data?.data?.data?.metaData,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.GET_TRANSACTION, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.GET_TRANSACTION, loading: false });
    });
};

export const getBalance = () => async (dispatch) => {
  dispatch({ type: Auth.GET_BALANCE, loading: true });
  post(`employer/inquiryBalance`)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: Auth.GET_BALANCE,
          loading: false,
          data: data?.data?.data,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.GET_BALANCE, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.GET_BALANCE, loading: false });
    });
};

export const toggleMailer = (values, CB) => (dispatch) => {
  const dispatchType = Auth.MAIL_RECIEVER_TOGGLE;
  dispatch({ type: dispatchType, loading: true });
  post(`generic/toggleEmail`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        dispatch({ type: dispatchType, loading: false });
        await dispatch(getProfile());
        CB && CB();
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const refreshToken = () => async (dispatch) => {
  let dispatchType = Auth.REFRESH_TOKEN;
  dispatch({ type: dispatchType, loading: true });
  get(`generic/refreshToken`)
    .then(async ({ data }) => {
      if (data?.data?.token) {
        await setValueInToLocalStorage(TOKEN, data.data.token);
        await getTokenAndSetIntoHeaders(data.data.token);
        dispatch(refreshTokenTimer(false));
      }
      dispatch({ type: dispatchType, loading: false });
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      dispatch(logout());
      handleError('Session expired!');
    });
};

export const logout =
  (history, status = "success", message = "Successfully logout") =>
    async (dispatch) => {
      try {
        await clearLocalstorage();
        dispatch({ type: Auth.LOGOUT_USER_API });
        history && history("/");
      } catch (e) {

      }

    };
