import FindCardHolderConstant from "../constants/FindCardHolder.constant";

const initialState = {
    data: {},
    getEmployeeDetailLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FindCardHolderConstant.GET_EMPLOYEE:
            return {
                ...state,
                data: action.data,
                getEmployeeDetailLoading: action.loading,
            };

        default:
            return state;
    }
};