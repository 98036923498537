import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshTokenTimer } from "../../store/actions/Common.action";
import { logout, refreshToken } from "../../store/actions/Auth.action";
import { Modal } from "antd";
import { checkTokenExpiration, formatTime } from "../../utils/methods";

const CRefreshModal = () => {
    const { isTimerOn, loading } = useSelector(({ common, auth }) => ({
        user: auth.user,
        isTimerOn: common.isTimerOn,
        loading: auth.refreshLoading,
    }));

    const [displayTime, setDisplayTime] = useState(null);
    const dispatch = useDispatch();

    const handleTokenExpirationCheck = useCallback(async () => {
        const data = await checkTokenExpiration();
        if (data) {
            if (data?.timeRemaining <= data?.twoMinutesInMillis) {
                setDisplayTime(formatTime(Math.floor(data?.timeRemaining / 1000).toFixed(2)));
                if (!isTimerOn) {
                    dispatch(refreshTokenTimer(true));
                }
                if (data?.timeRemaining < 0) {
                    setDisplayTime(null);
                    dispatch(refreshTokenTimer(false));
                    dispatch(logout());
                }
            }
        }
    }, [isTimerOn]);

    useEffect(() => {
        const intervalId = setInterval(handleTokenExpirationCheck, 1000);

        return () => clearInterval(intervalId);
    }, [handleTokenExpirationCheck]);

    const handleRefreshToken = () => {
        dispatch(refreshToken());
    };

    return (
        <Modal
            title="Refresh Session"
            centered
            open={isTimerOn}
            closable={false}
            maskClosable={false}
            okText="Refresh"
            confirmLoading={loading}
            onOk={handleRefreshToken}
            cancelButtonProps={{ hidden: true }}
            zIndex={9999}
        >
            <h5>You will be automatically logged out in {displayTime}.</h5>
        </Modal>
    );
};

export default CRefreshModal;