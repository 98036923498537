import React from 'react'

function CImage({url, className = '', alt = ""}) {
    return (
        <img
            src={url}
            className={className} draggable={false} alt={alt}
        />
    )
}

export default CImage
