// icon.js
import React from "react";
import IcoMoon from "react-icomoon";
const iconSet = require("./selection.json");

const CIcon = ({ ...props }) => {
    return <IcoMoon iconSet={iconSet} {...props} />;
};

export default CIcon;
