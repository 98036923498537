import RemittancesConstant from "../constants/Remittances.constant";

const initialState = {
    remittances: [],
    getLoading: false,
    metaData: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case RemittancesConstant.GET_REMITTANCES:
            return {
                ...state,
                remittances: action.data,
                getLoading: action.loading,
                metaData: action.metaData,
            };

        default:
            return state;
    }
};