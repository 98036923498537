import * as Yup from "yup";

const scheme = Yup.object().shape({
  empCode: Yup.string()
    .label("Employee Code")
    .required("Please enter Employee Code")
    .matches(/^[0-9A-z]*$/, "Only Alphanumeric or Numeric characters are allowed")
    .min(4, "Employee code must be atleast 4 characters")
    .max(16, "Employee code should not exceed 16 characters"),
});

export default scheme;