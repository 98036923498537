import { Button } from "antd";
import React from "react";
import CIcon from "../../assets/cIcon/CIcon";

const CButton = (props) => {

    const { type, title = '', containerClass = '', className = '', buttonLayout = '', disabled = false, loading = false, buttonType = '',
        onClick = () => null, leftIcon, svg = undefined, id = '', error = '', size = '', placeholder = '', icon = null, htmlType = '', rightIcon = undefined } = props;

    let SVG = svg;

    const renderIcons = () => {
        if (!loading) {
            if (leftIcon) {
                if (typeof leftIcon === 'function') {
                    return leftIcon('c-button-left-icon');
                } else if (typeof leftIcon === 'string') {
                    return <span> <CIcon className="c-button-left-icon" icon={leftIcon} /></span>;
                }
            }
            if (SVG) {
                return <span><SVG className='c-button-left-icon' fill='blue' /></span>;
            }
            if (icon) {
                return icon();
            }
        }
        return null;
    }

    return (
        <div className={`button-container ${buttonType} ${containerClass}`}>
            {placeholder?.length ? <label className="input-title" >{placeholder}</label> : null}
            <Button
                // {...props}
                {...(htmlType?.length && { htmlType: htmlType })}
                title={title}
                id={id}
                onClick={onClick}
                disabled={disabled || loading}
                className={`c-button ${buttonLayout} ${className} ${size === 'large' ? 'large' : ''} ${icon ? 'small' : ''}`}
                type={type}
                loading={loading}
            >
                {renderIcons()}
                {!icon ? <span>{loading ? 'Please wait' : title}</span> : null}
                {!loading ? rightIcon && rightIcon('c-button-left-icon') : null}
            </Button>
            {error ? <div className="error">{error}</div> : null}
        </div >
    )
};
export default CButton