const EMPLOYER = {

    GET_EMPLOYER: 'GET_EMPLOYER',
    ADD_EMPLOYER: 'ADD_EMPLOYER',

    SYNC_EMPLOYEERS: 'SYNC_EMPLOYEERS',

    TOGGLE_CLIENT_ACL: 'TOGGLE_CLIENT_ACL',
    DOWNLOAD_LIST: 'DOWNLOAD_LIST',

    GET_EMPLOYER_DASHOARD: 'GET_EMPLOYER_DASHOARD',

    ADD_ESHTABLISHMENTS: "ADD_ESHTABLISHMENTS",

    CHANGE_PASSWORD: "CHANGE_PASSWORD",

};

export default EMPLOYER;
