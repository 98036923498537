import EMPLOYEES from "../constants/Employees.constant";

const initialState = {
  getLoading: false,
  employees: [],
  employeesMetaData: {},
  employeeDetails: {},
  getEmployeeDetailLoading: false,
  uploadEmployeeLoading: false,
  changeBankLoading: false,
  addLoading: false,
  updateLoading: false,
  uploadOtherBankEmployeeLoading: false,
  blockActiveRequestLoading: false,
  downloadListLoading: false,
  transactions: [],
  otherBankTransactions: [],
  getTransactionLoading: false,
  transactionMetaData: false,
  otherBankTransactionMetaData: false,
  blockAppLoading: false,
  ActivateAppLoading: false,
  replaceCardLoading: false,
  syncLoading: false,
  changePINLoading: false,
  getBalanceLoading: false,

  appTransactions: [],
  appTransactionsMetaData: {},
  getAppTransactionsLoading: false,

  updateEidLoading: false,
  editLoading: false,

  statementRequestLoading: false,
  cardActivateOrBlockLoading: false,

  checkCardStatusLoading: false,

  ocrEIDLoading: false,
  getEIDFullDetailLoading: false,
  updateDirectEIDLoading: false,

  inactiveEmployeesLoading: false,
  checkSubscriptionStatusLoading: false,
  whiteBlackListingLoading: false,

  createMobileAppUserLoading: false,
  holdingMobileUserData: {},

  verifyMobileAppUserLoading: false,

  resendOTPMobileUserLoading: false,

  getOCRTokenLoading: false,
  holdingOCRTokenResponse: {},

  uploadUqudoImagesLoading: false,

  updateDOBLoading: false,

  addPassportLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case EMPLOYEES.GET_EMPLOYEES:
      return {
        ...state,
        getLoading: action.loading,
        employees: action.data || state.employees,
        employeesMetaData: action.metaData || state.employeesMetaData,
      };
    case EMPLOYEES.GET_EMPLOYEE_DETAILS:
      return {
        ...state,
        getEmployeeDetailLoading: action.loading,
        employeeDetails: action.employeeDetails,
      };
    case EMPLOYEES.CHANGE_BANK_TYPE:
      return {
        ...state,

        changeBankLoading: action.loading,
      };
    case EMPLOYEES.DOWNLOAD_LIST:
      return {
        ...state,

        downloadListLoading: action.loading,
      };
    case EMPLOYEES.UPLOAD_EMPLOYEE:
      return {
        ...state,
        uploadEmployeeLoading: action.loading,
      };
    case EMPLOYEES.UPLOAD_OTHER_BANK_EMPLOYEES:
      return {
        ...state,
        uploadOtherBankEmployeeLoading: action.loading,
      };
    case EMPLOYEES.ADD_EMPLOYEE:
      return {
        ...state,
        addLoading: action.loading,
      };

    case EMPLOYEES.UPDATE_EMPLOYEE:
      return {
        ...state,
        updateLoading: action.loading,
      };

    case EMPLOYEES.EDIT_EMPLOYEE:
      return {
        ...state,
        editLoading: action.loading,
      };

    case EMPLOYEES.BLOCK_ACTIVE_REQUEST:
      return {
        ...state,
        blockActiveRequestLoading: action.loading,
      };

    case EMPLOYEES.BLOCK_APP:
      return {
        ...state,
        blockAppLoading: action.loading,
      };

    case EMPLOYEES.ACTIVATE_APP:
      return {
        ...state,
        ActivateAppLoading: action.loading,
      };


    case EMPLOYEES.REPLACE_CARD:
      return {
        ...state,
        replaceCardLoading: action.loading,
      };

    case EMPLOYEES.GET_EMPLOYEE_TRANSACTION:
      return {
        ...state,
        transactions: action.data,
        getTransactionLoading: action.loading,
        transactionMetaData: action.metaData,
      };
    case EMPLOYEES.GET_EMPLOYEE_TRANSACTION_OTHER_BANK:
      return {
        ...state,
        otherBankTransactions: action.data,
        getTransactionLoading: action.loading,
        otherBankTransactionMetaData: action.metaData,
      };

    case EMPLOYEES.SYNC_EMPLOYEES:
      return {
        ...state,
        syncLoading: action.loading,
      };

    case EMPLOYEES.CHANGE_PIN:
      return {
        ...state,
        changePINLoading: action.loading,
      };
    case EMPLOYEES.GET_BALANCE:
      return {
        ...state,
        getBalanceLoading: action.loading,
      };

    case EMPLOYEES.EMPLOYEE_STATEMENT_REQUEST:
      return {
        ...state,
        statementRequestLoading: action.loading,
      };

    case EMPLOYEES.GET_APP_TRANSACTIONS:
      return {
        ...state,
        appTransactions: action.data,
        getAppTransactionsLoading: action.loading,
        appTransactionsMetaData: action.metaData,
      };

    case EMPLOYEES.UPDATE_EID:
      return {
        ...state,
        updateEidLoading: action.loading,
      };

    case EMPLOYEES.CARD_ACTIVATE_OR_BLOCK:
      return {
        ...state,
        cardActivateOrBlockLoading: action.loading,
      };

    case EMPLOYEES.CHECK_CARD_STATUS:
      return {
        ...state,
        checkCardStatusLoading: action.loading,
      };

    case EMPLOYEES.OCR_EID:
      return {
        ...state,
        ocrEIDLoading: action.loading,
      };

    case EMPLOYEES.GET_EID_FULL_DETAIL:
      return {
        ...state,
        getEIDFullDetailLoading: action.loading,
      };

    case EMPLOYEES.UPDATE_DIRECT_EID:
      return {
        ...state,
        updateDirectEIDLoading: action.loading,
      };

    case EMPLOYEES.INACTIVE_EMPLOYEES:
      return {
        ...state,
        inactiveEmployeesLoading: action.loading,
      };

    case EMPLOYEES.CHECK_SUBSCRIPTION_STATUS:
      return {
        ...state,
        checkSubscriptionStatusLoading: action.loading,
      };

    case EMPLOYEES.WHITELIST_BLACKLIST:
      return {
        ...state,
        whiteBlackListingLoading: action.loading,
      };

    case EMPLOYEES.CREATE_MOBILE_APP_USER:
      return {
        ...state,
        createMobileAppUserLoading: action.loading,
        holdingMobileUserData: action.data,
      };

    case EMPLOYEES.VERIFY_OTP_MOBILE_USER:
      return {
        ...state,
        verifyMobileAppUserLoading: action.loading,
      };

    case EMPLOYEES.RESEND_OTP_MOBILE_APP_USER:
      return {
        ...state,
        resendOTPMobileUserLoading: action.loading,
      };

    case EMPLOYEES.GET_OCR_TOKEN:
      return {
        ...state,
        getOCRTokenLoading: action.loading,
        holdingOCRTokenResponse: action.data,
      };

    case EMPLOYEES.UPLOAD_UQUDO_IMAGES:
      return {
        ...state,
        uploadUqudoImagesLoading: action.loading,
      };

    case EMPLOYEES.UPDATE_DOB:
      return {
        ...state,
        updateDOBLoading: action.loading,
      };

    case EMPLOYEES.ADD_PASSPORT:
      return {
        ...state,
        addPassportLoading: action.loading,
      };

    default:
      return state;
  }
};