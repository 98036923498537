const EMPLOYEES = {
  GET_EMPLOYEES: "GET_EMPLOYEES",
  UPLOAD_EMPLOYEE: "UPLOAD_EMPLOYEE",
  UPLOAD_OTHER_BANK_EMPLOYEES: "UPLOAD_OTHER_BANK_EMPLOYEES",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",

  EDIT_EMPLOYEE: "EDIT_EMPLOYEE",

  BLOCK_ACTIVE_REQUEST: "BLOCK_ACTIVE_REQUEST",
  GET_EMPLOYEE_DETAILS: "GET_EMPLOYEE_DETAILS",
  DOWNLOAD_LIST: "DOWNLOAD_LIST",
  CHANGE_BANK_TYPE: "CHANGE_BANK_TYPE",

  GET_EMPLOYEE_TRANSACTION: "GET_EMPLOYEE_TRANSACTION",

  BLOCK_APP: "BLOCK_APP",
  ACTIVATE_APP: "ACTIVATE_APP",
  REPLACE_CARD: "REPLACE_CARD",

  GET_EMPLOYEE_TRANSACTION_OTHER_BANK: "GET_EMPLOYEE_TRANSACTION_OTHER_BANK",

  GET_BALANCE: "GET_BALANCE",
  CHECK_CARD_STATUS: "CHECK_CARD_STATUS",
  GET_APP_TRANSACTIONS: "GET_APP_TRANSACTIONS",

  SYNC_EMPLOYEES: "SYNC_EMPLOYEES",

  CHANGE_PIN: "CHANGE_PIN",

  UPDATE_EID: "UPDATE_EID",

  EMPLOYEE_STATEMENT_REQUEST: "EMPLOYEE_STATEMENT_REQUEST",
  CARD_ACTIVATE_OR_BLOCK: "CARD_ACTIVATE_OR_BLOCK",

  OCR_EID: "OCR_EID",

  GET_EID_FULL_DETAIL: "GET_EID_FULL_DETAIL",

  UPDATE_DIRECT_EID: "UPDATE_DIRECT_EID",

  INACTIVE_EMPLOYEES: "INACTIVE_EMPLOYEES",

  CHECK_SUBSCRIPTION_STATUS: "CHECK_SUBSCRIPTION_STATUS",

  WHITELIST_BLACKLIST: "WHITELIST_BLACKLIST",

  CREATE_MOBILE_APP_USER: "CREATE_MOBILE_APP_USER",

  RESEND_OTP_MOBILE_APP_USER: "RESEND_OTP_MOBILE_APP_USER",

  VERIFY_OTP_MOBILE_USER: "VERIFY_OTP_MOBILE_USER",

  GET_OCR_TOKEN: "GET_OCR_TOKEN",

  UPLOAD_UQUDO_IMAGES: "UPLOAD_UQUDO_IMAGES",

  UPDATE_DOB: "UPDATE_DOB",

  ADD_PASSPORT: "ADD_PASSPORT",

};

export default EMPLOYEES;
