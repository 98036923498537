import React, { useRef } from "react";
import { Modal } from "antd";
import { Form, Formik } from "formik";
import scheme from "./Validations";
import { CButton, CField } from "../../uiComponents";
import { useDispatch } from "react-redux";
import { addPassport } from "../../store/actions/Employees.action";
import { getBase64, handleError, isImage, openFileInBrowser, renderDate } from "../../utils/methods";
import { IMAGE_FILETYPE, PDF_FILETYPE } from "../../utils/constants";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { AiOutlineDelete } from "react-icons/ai";
import { FileIcon } from "../../assets/svg";
import dayjs from "dayjs";

const AddForm = ({ isOpen, setIsOpen, loading, employeeId, clientId }) => {

    const dispatch = useDispatch();
    const form = useRef(null);

    const onCancel = () => {
        if (!loading) {
            setIsOpen(false);
        }
    };

    const callBack = () => {
        form.current.resetForm();
        setIsOpen(false);
    };

    const submit = (values) => {

        const body = new FormData();

        body.append('client', clientId)
        body.append('employee', employeeId)
        body.append('passportNumber', values?.passportNumber)
        body.append('passportExpiryDate', values?.passportExpiryDate)
        body.append('passportIssueDate', values?.passportIssueDate)
        body.append('passportFile', values?.passportFile)

        dispatch(addPassport(body, callBack));
    }

    const renderImage = (file) => {
        let setFieldValue = form.current?.setFieldValue;
        let values = form?.current?.values;
        try {
            if (file && Object.keys(file)?.length) {
                return (
                    <div className="c-preview-list">
                        <div className="preview margin-right_zero">
                            <div className="left" onClick={() => openFileInBrowser(file, false)}>
                                <div className="thumbnail">
                                    {isImage(file) ? <img src={file?.thumbUrl} /> : <FileIcon />}
                                </div>
                                <span>
                                    <div>{file?.name}</div>
                                    {values?.passportIssueDate && values?.passportExpiryDate ? <p>{renderDate(values?.passportIssueDate)} - {renderDate(values?.passportExpiryDate)}</p> : null}
                                </span>
                            </div>
                            {!loading ? <AiOutlineDelete className="c-icon" onClick={() => setFieldValue(`passportFile`, {})} /> : null}
                        </div>
                    </div>
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleFileChange = async (info, setFieldValue) => {
        const { status, originFileObj = {} } = info?.file;
        if (status !== 'uploading') {
            if (originFileObj?.type.includes("jpeg") || originFileObj?.type.includes("jpg") || originFileObj?.type.includes("png") || originFileObj?.type === PDF_FILETYPE) {
                await getBase64(originFileObj).then((uri) => {
                    originFileObj.thumbUrl = uri;
                    setFieldValue('passportFile', originFileObj);
                });
            } else {
                handleError("Only JPEG, PNG, and PDF files are allowed!");
            }
        }
    }

    return (
        <Modal
            title={"Add/Update passport"}
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            onCancel={onCancel}
            cancelButtonProps={{ hidden: true }}
            okButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Formik
                innerRef={form}
                validationSchema={scheme}
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{}}
                onSubmit={submit}
            >
                {({ errors, touched, handleSubmit, values, setFieldTouched, submitCount, handleChange, setFieldValue }) => {
                    let fileError = submitCount ? errors?.passportFile : touched?.passportFile && errors?.passportFile;

                    return <Form>
                        <div className="inner-form col">

                            <CField
                                name='passportIssueDate'
                                placeholder="Issue Date"
                                containerClass='margin-right_zero'
                                fieldType="date"
                                error={submitCount ? errors?.passportIssueDate : touched?.passportIssueDate && errors?.passportIssueDate}
                                onBlur={() => setFieldTouched('passportIssueDate', true, true)}
                                format={"DD-MMM-YYYY"}
                                disableDate={(current) => current > dayjs()}
                                disabled={loading}
                                value={values?.passportIssueDate}
                                onChange={(val) => setFieldValue('passportIssueDate', val)}
                            />

                            <CField
                                name='passportExpiryDate'
                                placeholder="Expiry Date"
                                containerClass='margin-right_zero'
                                fieldType="date"
                                error={submitCount ? errors?.passportExpiryDate : touched?.passportExpiryDate && errors?.passportExpiryDate}
                                onBlur={() => setFieldTouched('passportExpiryDate', true, true)}
                                format={"DD-MMM-YYYY"}
                                disableDate={(current) => current < dayjs()}
                                disabled={loading}
                                value={values?.passportExpiryDate}
                                onChange={(val) => setFieldValue('passportExpiryDate', val)}
                            />

                            <CField
                                name='passportNumber'
                                placeholder='passport Number'
                                containerClass='margin-right_zero'
                                type="text"
                                error={submitCount ? errors?.passportNumber : touched?.passportNumber && errors?.passportNumber}
                                onBlur={() => setFieldTouched('passportNumber', true, true)}
                                value={values?.passportNumber}
                                disabled={loading}
                                onChange={(e) => handleChange('passportNumber')(e.target.value)}
                            />

                            <Dragger
                                onChange={(info) => handleFileChange(info, setFieldValue)}
                                accept={`${IMAGE_FILETYPE},${PDF_FILETYPE}`}
                                showUploadList={false}
                                height={150}
                                disabled={loading}
                                className={`w-100 margin-top_15 margin-bottom_15 ${fileError ? "error" : ""}`}
                            >
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p className="ant-upload-text">Click or drag file here to upload</p>
                                <p className="ant-upload-hint">Only Images and PDF are allowed</p>
                            </Dragger>

                            {fileError ? <p className="small-red-text" >{errors?.passportFile}</p> : null}

                            {renderImage(values?.passportFile)}

                        </div>

                        <CButton
                            containerClass="margin-right_zero"
                            onClick={handleSubmit}
                            type="submit"
                            htmlType="submit"
                            title={'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </Form>
                }}
            </Formik>

        </Modal>
    );
};
export default React.memo(AddForm);