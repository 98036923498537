import ESTABLISHMENT from "../constants/Establishment.constant";

const initialState = {
    establishments: [],
    getLoading: false,
    metaData: {},

    getEmployerLoading: false,
    employers: [],

    addEstalishmentsLoading: false,

    uploadEstLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case ESTABLISHMENT.GET_EMPLOYERS:
            return {
                ...state,
                getEmployerLoading: action.loading,
                employers: action.data || state.employers,
            };


        case ESTABLISHMENT.ADD_ESHTABLISHMENTS:
            return {
                ...state,
                addEstalishmentsLoading: action.loading,
            };

        case ESTABLISHMENT.GET_ESTABLISHMENT_IDS:
            return {
                ...state,
                establishments: action.data,
                getLoading: action.loading,
                metaData: action.metaData,
            };

        case ESTABLISHMENT.UPLOAD_ESTABLISHMENTS:
            return {
                ...state,
                uploadEstLoading: action.loading,
            };

        default:
            return state;
    }
};
