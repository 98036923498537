export const RADIO = 'RADIO';
export const CHECKBOX = 'CHECKBOX';

export const PI_CHART_SELECTIONS = [
  {
    _id: "BY_CATEGORY",
    name: "Chart by Category",
  },
  {
    _id: "BY_CENTIV_CARD",
    name: "Chart by Centiv Card",
  },
];

export const STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const STATUS_ARRAY = [
  { _id: "PENDING", name: "Pending" },
  { _id: "APPROVED", name: "Approved" },
  { _id: "REJECTED", name: "Rejected" },
];

export const ACTION_ARRAY = [
  { _id: "PENDING", name: "Pending" },
  { _id: "APPROVED", name: "Approved" },
  { _id: "REJECTED", name: "Rejected" },
];

export const ONBOARD_APPROVAL_TYPE = [
  {
    _id: "BRANCH",
    name: "Branch OnBoard",
    label: "Branch OnBoard",
    value: "BRANCH",
  },
  {
    _id: "CLIENT",
    name: "Clients OnBoard",
    label: "Clients OnBoard",
    value: "CLIENT",
  },
];

export const ACTIVE_INACTIVE = [
  {
    _id: "ACTIVE",
    name: "Active",
  },
  {
    _id: "INACTIVE",
    name: "In-Active",
  },
];
export const TOPIC_OBJECT = [
  { _id: 1, name: "INITIAL_UNVERIFIED" },
  { _id: 2, name: "INITIAL_VERIFICATION_APPROVED" },
  { _id: 3, name: "ON_BOARD_REQUEST" },
  { _id: 4, name: "ON_BOARD_REQUEST_APPROVED" },
  { _id: 5, name: "ON_BOARD_REQUEST_REJECT" },
];
export const TYPE_OBJECT = [
  { key: "isFreeZone", name: "Free Zone" },
  { key: "isNotFreeZone", name: "Not Free Zone" },
  { key: "isDomesticEmployer", name: "Domestic Employer" },
];
export const YES_NO = [
  {
    _id: "true",
    name: "Yes",
  },
  {
    _id: "false",
    name: "No",
  },
];

export const YESNO = [
  {
    _id: "YES",
    name: "Yes",
  },
  {
    _id: "NO",
    name: "No",
  },
];

export const CARD_TYPE = [
  {
    _id: "CENTIV",
    name: "Centiv Card",
  },
  {
    _id: "PAYD",
    name: "PayD Card",
  },
];

export const USER_TYPE = {
  EXCHANGE_HOUSE: "EXCHANGE_HOUSE",
  EXCHANGE_HOUSE_USER: "EXCHANGE_HOUSE_USER",
  EXCHANGE_HOUSE_BRANCH: "EXCHANGE_HOUSE_BRANCH",
  EXCHANGE_HOUSE_BRANCH_USER: "EXCHANGE_HOUSE_BRANCH_USER",
};
export const USER_TYPE_CAMELCASE = {
  EXCHANGE_HOUSE: "exchangeHouse",
  EXCHANGE_HOUSE_BRANCH: "exchangeHouseBranch",
  EXCHANGE_HOUSE_USER: "exchangeHouseUser",
  EXCHANGE_HOUSE_BRANCH_USER: "exchangeHouseBranchUser",
};
export const LOGIN_TYPES = {
  EXCHANGE_HOUSE: "exchangeHouse",
  EXCHANGE_HOUSE_BRANCH: "exchangeHouseBranch",
};

export const USER_TYPE_ARRAY = [
  { name: "Head Office", _id: "EXCHANGE_HOUSE" },
  // { name: "Exchange House User", _id: "EXCHANGE_HOUSE_USER" },
  { name: "Branch", _id: "EXCHANGE_HOUSE_BRANCH" },
  // { name: "Branch User", _id: "EXCHANGE_HOUSE_BRANCH_USER" },
];


export const USER_TYPE_ARRAY_CAMELCASE = [
  { name: "Head Office", _id: "exchangeHouse" },
  { name: "Branch", _id: "exchangeHouseBranch" },
];
// export const USER_TYPE_ARRAY = {
//   EXCHANGE_HOUSE: "EXCHANGE_HOUSE",
//   EXCHANGE_HOUSE_USER: "EXCHANGE_HOUSE_USER",
//   EXCHANGE_HOUSE_BRANCH: "EXCHANGE_HOUSE_BRANCH",
//   EXCHANGE_HOUSE_BRANCH_USER: "EXCHANGE_HOUSE_BRANCH_USER",
// }
export const EID_EXPIRY = [
  {
    _id: "NOT_AVAILABLE",
    name: "Not Available",
  },
  {
    _id: "EXPIRED",
    name: "Expired",
  },
  {
    _id: "NEAR_TO_EXPIRE",
    name: "Near to Expire",
  },
];

export const EXCEL_FILETYPES =
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const CSV_FILETYPES = "text/csv";
export const PDF_FILETYPE = "application/pdf";
export const IMAGE_FILETYPE = "image/*";

export const KYC = {
  INITIAL_UNVERIFIED: "INITIAL_UNVERIFIED",
  INITIAL_VERIFICATION_APPROVED: "INITIAL_VERIFICATION_APPROVED",
  ON_BOARD_REQUEST: "ON_BOARD_REQUEST",
  ON_BOARD_REQUEST_APPROVED: "ON_BOARD_REQUEST_APPROVED",
  ON_BOARD_REQUEST_REJECT: "ON_BOARD_REQUEST_REJECT",
  BANK_CHANGE_TYPE_REQUEST: 'BANK_CHANGE_TYPE_REQUEST',
};

export const INITIALS = [
  {
    _id: "MR",
    name: "MR",
  },
  {
    _id: "MS",
    name: "MS",
  },
];
export const GENDER = [
  {
    _id: "M",
    name: "Male",
  },
  {
    _id: "F",
    name: "Female",
  },
];

export const ACL_OPTIONS = {
  DASHBOARD: ["DASHBOARD_VIEW"],
  REQUESTS: ["REQUESTS_MAKER", "REQUESTS_CHECKER"],

  EMPLOYER: ["EMPLOYER_VIEW"],
  TRANSACTIONS: ["TRANSACTIONS_VIEW"],
  CENTIV_CARD: ["CENTIV_CARD_VIEW"],
  PROCESS_SALARIES: ["PROCESSSALARIES_CAN_PROCESS"],
};
export const TOPIC = {
  CLIENT_PROFILE_UPDATE: "CLIENT_PROFILE_UPDATE",
  INITIAL_VERIFICATION_APPROVED: "INITIAL_VERIFICATION_APPROVED",
  ON_BOARD_REQUEST: "ON_BOARD_REQUEST",
  ON_BOARD_REQUEST_APPROVED: "ON_BOARD_REQUEST_APPROVED",
  ON_BOARD_REQUEST_REJECT: "ON_BOARD_REQUEST_REJECT",
  EMPLOYEES_FILE_UPLOAD: "EMPLOYEES_FILE_UPLOAD",
  EMPLOYEES_OTHER_BANK_FILE_UPLOAD: "EMPLOYEES_OTHER_BANK_FILE_UPLOAD",
  EMPLOYEE_ONBOARD_REQUEST: "EMPLOYEE_ONBOARD_REQUEST",
  EMPLOYEE_ONBOARD_APPROVED: "EMPLOYEE_ONBOARD_APPROVED",
  EMPLOYEE_ONBOARD_REJECT: "EMPLOYEE_ONBOARD_REJECT",
  EMPLOYEE_UPDATE_REQUEST: "EMPLOYEE_UPDATE_REQUEST",
  SALARY_FILE_UPLOAD: "SALARY_FILE_UPLOAD",
  DEPOSIT_FILE_UPLOAD: "DEPOSIT_FILE_UPLOAD",
  CENTIV_CARD_REQUEST: "CENTIV_CARD_REQUEST",
  CARD_REPLACEMENT_REQUEST: "CARD_REPLACEMENT_REQUEST",
  EMPLOYEE_APPLICATION_BLOCK_REQUEST: "EMPLOYEE_APPLICATION_BLOCK_REQUEST",
  EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST: "EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST",
  USER_BLOCK_REQUEST: "USER_BLOCK_REQUEST",
  CHANGE_EMPLOYEE_TYPE: "CHANGE_EMPLOYEE_TYPE",
  USER_UNBLOCK_REQUEST: "USER_UNBLOCK_REQUEST",
  ESTABLISHMENT_IDS_ADDED: "ESTABLISHMENT_IDS_ADDED",
  UPDATE_MOL_NO: "UPDATE_MOL_NO",
  ESTABLISHMENT_ID_ADDED: "ESTABLISHMENT_ID_ADDED",
  CENTIV_LOAD_FUNDS: "CENTIV_LOAD_FUNDS",
  EMPLOYEE_EID_UPDATE: "EMPLOYEE_EID_UPDATE",
  EMPLOYEE_PHONE_UPDATE: "EMPLOYEE_PHONE_UPDATE",
  CARD_PIN_CHANGE: "CARD_PIN_CHANGE",
  EMPLOYEE_STATEMENT_REQUEST: "EMPLOYEE_STATEMENT_REQUEST",
  UPDATE_DATE_OF_BIRTH: 'UPDATE_DATE_OF_BIRTH',
  UPDATE_NATIONALITY: 'UPDATE_NATIONALITY',
  UPDATE_EMP_CODE: 'UPDATE_EMP_CODE',
  UPDATE_PASSPORT: 'UPDATE_PASSPORT',
  //Exchange house
  EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST: 'EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST',
  BULK_ESTABLISHMENT_IDS_ADDED: 'BULK_ESTABLISHMENT_IDS_ADDED',
  EOS_FILE_UPLOAD: 'EOS_FILE_UPLOAD',
  INACTIVE_EMPLOYEES: "INACTIVE_EMPLOYEES",
};
export const ACTION = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};
export const CLIENT_APPROVAL_STATUS = {
  APPROVED_BY_CLIENT_ADMIN: "APPROVED_BY_CLIENT_ADMIN",
  APPROVED_BY_CLIENT: "APPROVED_BY_CLIENT",
  REJECTED_BY_CLIENT: "REJECTED_BY_CLIENT",
  APPROVED_BY_ADMIN: "APPROVED_BY_ADMIN",
  REJECTED_BY_ADMIN: "REJECTED_BY_ADMIN",
  REJECTED_BY_EXCHANGE_HOUSE: 'REJECTED_BY_EXCHANGE_HOUSE',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH',
  REJECTED_BY_EXCHANGE_HOUSE_USER: 'REJECTED_BY_EXCHANGE_HOUSE_USER',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER',
};

export const APPROVAL_MESSAGES = {
  APPROVED_BY_CLIENT_ADMIN: "Waiting For Your Approval",
  APPROVED_BY_CLIENT: "Processing",
  APPROVED_BY_ADMIN: "Processed",
  REJECTED_BY_ADMIN: "Rejected By KamelPay",
  REJECTED_BY_EXCHANGE_HOUSE: 'Rejected By Exchange House',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH: 'Rejected By Exchange House Branch',
  REJECTED_BY_EXCHANGE_HOUSE_USER: 'Rejected By Exchange House User',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER: 'Rejected By Exchange House Branch User',
};

export const REJECTED_ARRAY_EH = [
  { _id: CLIENT_APPROVAL_STATUS.REJECTED_BY_ADMIN, name: "KamelPay" },
  { _id: CLIENT_APPROVAL_STATUS.REJECTED_BY_EXCHANGE_HOUSE, name: "Exchange House" },
  { _id: CLIENT_APPROVAL_STATUS.REJECTED_BY_EXCHANGE_HOUSE_BRANCH, name: "Exchange House Branch" },
  { _id: CLIENT_APPROVAL_STATUS.REJECTED_BY_EXCHANGE_HOUSE_USER, name: "Exchange House User" },
  { _id: CLIENT_APPROVAL_STATUS.REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER, name: "Exchange House Branch User" },
];

export const CARD_STATUS = {
  PENDING: "Pending",
  COMPLIANCE_BANK_PASSED: "Compliance Bank Passed",
  COMPLIANCE_BANK_FAIL: "Compliance Bank Fail",
  CARD_ON_PRINTING: "Card On Printing",
  CARD_ON_PRINTED: "Card On Printed",
  DELIVERED: "Delivered",
};
export const CARD_STATUS_ARRAY = [
  { _id: "PENDING", title: "Pending" },
  { _id: "COMPLIANCE_BANK_PASSED", title: "Compliance Bank Passed" },
  { _id: "COMPLIANCE_BANK_FAIL", title: "Compliance Bank Fail" },
  { _id: "CARD_ON_PRINTING", title: "Card On Printing" },
  { _id: "CARD_ON_PRINTED", title: "Card On Printed" },
  { _id: "DELIVERED", title: "Delivered" },
];
//dummy array data
export const APPROVAL_MESSAGES_ARRAY = [
  {
    _id: "APPROVED_BY_CLIENT_ADMIN",
    name: "Waiting For Your Approval",
    title: "Waiting For Your Approval",
  },
  { _id: "APPROVED_BY_CLIENT", name: "Processing", title: "Processing" },
  { _id: "APPROVED_BY_ADMIN", name: "Processed", title: "Processed" },
  {
    _id: "REJECTED_BY_ADMIN",
    name: "Rejected By KamelPay",
    title: "Rejected By KamelPay",
  },
  { _id: "REJECTED_BY_CLIENT", name: "Rejected", title: "Rejected" },
];
export const SALARY_STATUS_ARRAY = (type = '') => {
  if (type === SALARY_TYPES_OBJ.WPS) {
    return {
      title: 'Get PAF',
      headerText: 'PAF Id',
      steps: [
        { _id: "PAF_RECEIVED", name: "PAF Received", title: "PAF Received" },
        { _id: "PAF_FILE_UPLOADED", name: "PAF Processed", title: "PAF Processed" },
        { _id: "TOPUP_REQUEST_GENERATED", name: "Card Load Request Sent", title: "Card Load Request Sent" },
        { _id: "SALARY_CREDITED", name: "Salary Credited", title: "Salary Credited" },
      ]
    }
  } else if (type === SALARY_TYPES_OBJ.NON_WPS) {
    return {
      title: 'Get Salary File',
      headerText: 'Salary Id',
      steps: [
        { _id: "TOPUP_REQUEST_GENERATED", name: "Card Load Request Sent", title: "Card Load Request Sent" },
        { _id: "SALARY_CREDITED", name: "Salary Credited", title: "Salary Credited" }
      ]
    }
  } else if (type === SALARY_TYPES_OBJ.OTHER_BANK) {
    return {
      title: 'Get SIF',
      headerText: 'SIF Id',
      steps: [
        { _id: "SIF_RECEIVED", name: "SIF Sent", title: "SIF Sent" },
        { _id: "SIF_FILE_UPLOADED", name: "SIF Ack Received", title: "SIF Ack Received" }
      ]
    }
  }
  return {};
};

export const EMPLOYEE_TYPE = [
  { _id: "KAMELPAY", name: "Kamel Pay" },
  { _id: "OTHER_BANK", name: "Other Bank" },
];

export const EMPLOYEE_TYPE_OBJ = {
  KAMELPAY: "KAMELPAY",
  OTHER_BANK: "OTHER_BANK"
}

export const ROLES = [
  {
    _id: "MAKER",
    name: "Maker",
    roles: {
      DASHBOARD: ["DASHBOARD_VIEW"],
      // MANAGE_ACCESS: ["MANAGE_ACCESS"],
      EMPLOYEES: ["EMPLOYEES_READ", "EMPLOYEES_CREATE"],
      CENTIV_CARD: ["CENTIV_CARD_VIEW"],
      TRANSACTIONS: ["TRANSACTIONS_VIEW"],
      REQUESTS: ["REQUESTS_MAKER"],
      PROCESS_SALARIES: ["PROCESSSALARIES_CAN_PROCESS"],
    },
  },
  // {
  //   _id: "CHECKER",
  //   name: "Checker",
  //   roles: {
  //     DASHBOARD: ["DASHBOARD_VIEW"],
  //     EMPLOYEES: ["EMPLOYEES_READ", "EMPLOYEES_CREATE"],
  //     CENTIV_CARD: ['CENTIV_CARD_VIEW'],
  //     TRANSACTIONS: ["TRANSACTIONS_VIEW"],
  //     REQUESTS: ["REQUESTS_MAKER", 'REQUEST_CHECKER'],
  //     PROCESS_SALARIES: ["PROCESSSALARIES_VIEW", 'PROCESSSALARIES_CAN_UPLOAD'],
  //   },
  // },
];

export const OTP_CONSTANT = {
  CARD_ACTIVATION: "CARD_ACTIVATION",
  PIN_CHANGE: "PIN_CHANGE",
}

export const EMPLOYEE_DOCUMENTS = [
  // { _id: 4, name: "Sponsor Document" },
  { _id: 5, name: "Labour Card" },
  { _id: 6, name: "Emirates Id" },
  { _id: 7, name: "Residence Visa" },
  { _id: 8, name: "Passport" },
];

export const PAYROLL_TYPES = [
  { _id: "WPS", name: "WPS" },
  { _id: "NON_WPS", name: "NON WPS" },
];

export const SALARY_TYPES = [
  { _id: "WPS", name: "WPS" },
  { _id: "NON-WPS", name: "Non WPS" },
  { _id: "OtherBank", name: "Other Bank" },
];

export const SALARY_TYPES_OBJ = {
  WPS: 'WPS',
  NON_WPS: 'NON-WPS',
  OTHER_BANK: 'OtherBank'
};

export const EMPLOYER_DOCUMENTS = [
  { _id: 1, name: "Trade License" },
  { _id: 2, name: "Establishment Card" },
  { _id: 3, name: "Labour Card" },
  { _id: 4, name: "Sponsor Document" },
  { _id: 9, name: "Mansha List" },
];

export const IMPORTANT_DOCS = [
  { _id: 1, name: "Trade License" },
  { _id: 4, name: "Sponsor Document" },
];

export const CARD_API_IDS = {
  BLOCK_APPLICATION: "BLOCK_APPLICATION",
  ACTIVATE_APPLICATION: "ACTIVATE_APPLICATION",
  BLOCK_CARD: "BLOCK_CARD",
  ACTIVATE_CARD: "ACTIVATE_CARD",
  REPLACE_CARD: "REPLACE_CARD",
  USER_BLOCK_REQUEST: "USER_BLOCK_REQUEST",
};

export const WHITELIST_BLACKLISTCAPS = {
  WHITE_LIST: 'WHITELIST',
  BLACK_LIST: 'BLACKLIST'
}

export const WHITELIST_BLACKLIST = {
  WHITE_LIST: 'WhiteList',
  BLACK_LIST: 'BlackList'
}

export const REPORTS = [
  {
    _id: "REMITTANCE",
    name: "Remittance Report",
    fileName: "remittance_report",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/remittance+report.xlsx",
  },
  {
    _id: "CASHOUT",
    name: "Cashout Report",
    fileName: "cashout_report",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/cash-withdrawal-report.xlsx",
  },
  {
    _id: "BILLPAY",
    name: "BillPay Report",
    fileName: "billPay_report",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/billpay.xlsx",
  },
  {
    _id: "ADVANCE_SALARY",
    name: "Advance Salary Report",
    fileName: "advance_salary_report",
    url: "https://firebasestorage.googleapis.com/v0/b/kamelpay-2a233.appspot.com/o/advance%20salary.xlsx?alt=media&token=f29c52de-ca67-4fa4-a08d-27fcc7869340",
  },
  {
    _id: "SALARY_PROCESSED",
    name: "Salary Processed Report",
    fileName: "salary_processed_report",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed.xlsx",
  },
  {
    _id: "SALARY_NOT_PROCESSED",
    name: "Salary Not Processed",
    fileName: "salary_not_processed",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed-cards.xlsx",
  },
  {
    _id: "ALL_CARDS_SALARY",
    name: "All Cards Salary Report",
    fileName: "all_cards_salary_report",
    // url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed-cards.xlsx",
  },
  {
    _id: "SMS_CARDS_DETAILS",
    name: "SMS Card Details Report",
    fileName: "sms_card_details_report",
  },
];

export const SALARY_REPORTS = [
  {
    _id: "SALARY_PROCESSED",
    name: "Salary Processed Report",
    fileName: "salary_processed_report",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed.xlsx",
  },
  {
    _id: "SALARY_NOT_PROCESSED",
    name: "Salary Not Processed",
    fileName: "salary_not_processed",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed-cards.xlsx",
  },
];

export const approvalStatusArray = (topic) =>
  topic === TOPIC.ON_BOARD_REQUEST ||
    topic === TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST
    ? [
      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
      { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
      { _id: "", title: "Approved By Admin" },
      { _id: "", title: "Compliance Passed" },
      { _id: "APPROVED_BY_ADMIN", title: "Onboarded" },
    ]
    : topic === TOPIC.ESTABLISHMENT_IDS_ADDED || topic === TOPIC.ESTABLISHMENT_ID_ADDED || topic === TOPIC.BULK_ESTABLISHMENT_IDS_ADDED
      ? [
        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
        { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
        { _id: "", title: "Approved By Admin" },
        { _id: "APPROVED_BY_ADMIN", title: "Processed" },
      ]
      : topic === TOPIC.INACTIVE_EMPLOYEES
        ? [
          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
          { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
          { _id: "", title: "Approved By Admin" },
          { _id: "APPROVED_BY_ADMIN", title: "Processed" },
        ]
        : topic === TOPIC.UPDATE_MOL_NO || TOPIC.UPDATE_NATIONALITY || TOPIC.UPDATE_EMP_CODE || TOPIC.UPDATE_PASSPORT
          ? [
            { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
            { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
            { _id: "", title: "Approved By Admin" },
            { _id: "APPROVED_BY_ADMIN", title: "Processed" },
          ]
          : topic === TOPIC.EMPLOYEE_PHONE_UPDATE ||
            topic === TOPIC.EMPLOYEE_EID_UPDATE ||
            topic === TOPIC.UPDATE_DATE_OF_BIRTH ||
            topic === TOPIC.CARD_PIN_CHANGE
            ? [
              { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
              { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
              { _id: "", title: "Approved By Admin" },
              { _id: "APPROVED_BY_ADMIN", title: "Processed" },
            ]
            : topic === TOPIC.CHANGE_EMPLOYEE_TYPE ||
              topic === TOPIC.EMPLOYEE_APPLICATION_BLOCK_REQUEST ||
              topic === TOPIC.EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST ||
              topic === TOPIC?.CENTIV_LOAD_FUNDS
              ? [
                { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
                { _id: "", title: "Approved By Admin" },
                { _id: "APPROVED_BY_ADMIN", title: "Processed" },
              ]
              : topic === TOPIC.EMPLOYEE_ONBOARD_REQUEST
                ? [
                  { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                  { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
                  { _id: "", title: "Approved By Admin" },
                  { _id: "", title: "Documents Checked" },
                  { _id: "", title: "Compliance Passed" },
                  { _id: "", title: "Card Printed" },
                  { _id: "", title: "Courier on the way" },
                  { _id: "APPROVED_BY_ADMIN", title: "Card Delivered" },
                ]
                : topic === TOPIC.EMPLOYEES_FILE_UPLOAD
                  ? [
                    { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                    { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
                    { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                    { _id: "", title: "Compliance Passed" },
                    { _id: "", title: "Card Printed" },
                    { _id: "", title: "Courier on the way" },
                    { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                  ]
                  : topic === TOPIC.CARD_REPLACEMENT_REQUEST
                    ? [
                      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                      { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
                      { _id: "", title: "Approved By Admin" },
                      { _id: "", title: "Card Permanently Blocked" },
                      { _id: "", title: "Replacement Request Sent" },
                      { _id: "", title: "Card Printed" },
                      { _id: "", title: "Courier on the way" },
                      { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                    ]
                    : topic === TOPIC.DEPOSIT_FILE_UPLOAD
                      ? [
                        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                        { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
                        { _id: "", title: "Approved By Admin" },
                        { _id: "APPROVED_BY_ADMIN", title: "Company Account Credited" },
                      ]
                      : topic === TOPIC.SALARY_FILE_UPLOAD
                        ? [
                          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                          { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
                          { _id: "", title: "Approved By Admin" },
                          { _id: "APPROVED_BY_ADMIN", title: "Salary Processed" },
                        ]
                        : topic === TOPIC.CLIENT_PROFILE_UPDATE || topic === TOPIC.EMPLOYEE_STATEMENT_REQUEST
                          ? [
                            { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                            { _id: "APPROVED_BY_CLIENT", title: "Approved By Head Office" },
                            { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                          ]
                          : [];

export const NATIONALITY = [
  { _id: "AF", name: "Afghanistan" },
  { _id: "AX", name: "Aland Islands" },
  { _id: "AL", name: "Albania" },
  { _id: "DZ", name: "Algeria" },
  { _id: "AS", name: "American Samoa" },
  { _id: "AD", name: "Andorra" },
  { _id: "AO", name: "Angola" },
  { _id: "AI", name: "Anguilla" },
  { _id: "AQ", name: "Antarctica" },
  { _id: "AG", name: "Antigua And Barbuda" },
  { _id: "AR", name: "Argentina" },
  { _id: "AM", name: "Armenia" },
  { _id: "AW", name: "Aruba" },
  { _id: "AU", name: "Australia" },
  { _id: "AT", name: "Austria" },
  { _id: "AZ", name: "Azerbaijan" },
  { _id: "BS", name: "Bahamas" },
  { _id: "BH", name: "Bahrain" },
  { _id: "BD", name: "Bangladesh" },
  { _id: "BB", name: "Barbados" },
  { _id: "BY", name: "Belarus" },
  { _id: "BE", name: "Belgium" },
  { _id: "BZ", name: "Belize" },
  { _id: "BJ", name: "Benin" },
  { _id: "BM", name: "Bermuda" },
  { _id: "BT", name: "Bhutan" },
  { _id: "BO", name: "Bolivia" },
  { _id: "BQ", name: "Caribbean Netherland" },
  { _id: "BA", name: "Bosnia - Herzegovina" },
  { _id: "BW", name: "Botswana" },
  { _id: "BV", name: "Bouvet Island" },
  { _id: "BR", name: "Brazil" },
  { _id: "IO", name: "British Indian Ocean" },
  { _id: "VG", name: "US Virgin Islands" },
  { _id: "BN", name: "Brunei Darussalam " },
  { _id: "BG", name: "Bulgaria" },
  { _id: "BF", name: "Burkina Faso" },
  { _id: "BI", name: "Burundi" },
  { _id: "KH", name: "Cambodia" },
  { _id: "CM", name: "Cameroon" },
  { _id: "CA", name: "Canada" },
  { _id: "CV", name: "Cape Verde" },
  { _id: "KH", name: "Cayman Islands" },
  { _id: "CF", name: "Central African" },
  { _id: "TD", name: "Chad" },
  { _id: "CL", name: "Chile" },
  { _id: "CX", name: "Christmas Islands" },
  { _id: "CC", name: "Cocos Islands" },
  { _id: "CO", name: "Columbia" },
  { _id: "KM", name: "Comoro Islands" },
  { _id: "CG", name: "Congo" },
  { _id: "CD", name: "Congo - Kinshasa" },
  { _id: "CK", name: "Cook Islands" },
  { _id: "CR", name: "Costa Rica" },
  { _id: "CI", name: "Cote D'Ivore" },
  { _id: "XP", name: "Country XPD & XPT" },
  { _id: "HR", name: "Croatia" },
  { _id: "CU", name: "Cuba" },
  { _id: "CW", name: "Curacao" },
  { _id: "CY", name: "Cyprus" },
  { _id: "CZ", name: "Czech Republic" },
  { _id: "DK", name: "Denmark" },
  { _id: "DJ", name: "Djibouti" },
  { _id: "DM", name: "Dominica" },
  { _id: "DO", name: "Dominican Republic" },
  { _id: "TP", name: "East Timor" },
  { _id: "EC", name: "Ecuador" },
  { _id: "EG", name: "Egypt" },
  { _id: "SV", name: "El Salvador" },
  { _id: "GQ", name: "Equatorial Guinea" },
  { _id: "ER", name: "Eritrea" },
  { _id: "EE", name: "Estonia" },
  { _id: "ET", name: "Ethiopia" },
  { _id: "XE", name: "Europa" },
  { _id: "EU", name: "European In Country" },
  { _id: "FK", name: "Falkland Islands" },
  { _id: "FO", name: "Faroe Islands" },
  { _id: "FJ", name: "Fiji" },
  { _id: "FI", name: "Finland" },
  { _id: "FR", name: "France" },
  { _id: "GF", name: "French Guiana" },
  { _id: "PF", name: "French Polynesia" },
  { _id: "TF", name: "French Southern Ter" },
  { _id: "GA", name: "Gabon" },
  { _id: "GM", name: "Gambia" },
  { _id: "GE", name: "Georgia" },
  { _id: "DE", name: "Germany" },
  { _id: "GH", name: "Ghana" },
  { _id: "GI", name: "Gibraltar" },
  { _id: "GR", name: "Greece" },
  { _id: "GL", name: "Greenland" },
  { _id: "GD", name: "Grenada" },
  { _id: "GP", name: "Guadeloupe" },
  { _id: "GU", name: "Guam" },
  { _id: "GT", name: "Guatemala" },
  { _id: "GG", name: "Guernsey Minor Ch" },
  { _id: "GN", name: "Guinea" },
  { _id: "GW", name: "Guinea-Bissau" },
  { _id: "GY", name: "Guyana" },
  { _id: "HT", name: "Haiti" },
  { _id: "HM", name: "Heard Island" },
  { _id: "VA", name: "Holy See" },
  { _id: "HN", name: "Honduras" },
  { _id: "HK", name: "Hong Kong" },
  { _id: "HU", name: "Hungary" },
  { _id: "IS", name: "Iceland" },
  { _id: "IN", name: "India" },
  { _id: "ID", name: "Indonesia" },
  { _id: "IR", name: "Iran" },
  { _id: "IQ", name: "Iraq" },
  { _id: "IE", name: "Ireland" },
  { _id: "IM", name: "Isle of Man" },
  { _id: "IL", name: "Israel" },
  { _id: "IT", name: "Italy" },
  { _id: "JM", name: "Jamaica" },
  { _id: "JP", name: "Japan" },
  { _id: "JE", name: "Jersey" },
  { _id: "JO", name: "Jordan" },
  { _id: "KZ", name: "Kazakstan" },
  { _id: "KE", name: "Kenya" },
  { _id: "KI", name: "Kiribati" },
  { _id: "KP", name: "North Korea" },
  { _id: "KR", name: "South Korea" },
  { _id: "KW", name: "Kuwait" },
  { _id: "KG", name: "Kyrgyzstan" },
  { _id: "LA", name: "Laos" },
  { _id: "LV", name: "Latvia" },
  { _id: "LB", name: "Lebanon" },
  { _id: "LS", name: "Lesotho" },
  { _id: "LR", name: "Liberia" },
  { _id: "LY", name: "Libya " },
  { _id: "LI", name: "Liechtenstein" },
  { _id: "LT", name: "Lithuania" },
  { _id: "LU", name: "Luxembourg" },
  { _id: "MO", name: "Macau" },
  { _id: "MK", name: "Macedonia" },
  { _id: "MG", name: "Madagascar" },
  { _id: "MW", name: "Malawi" },
  { _id: "MY", name: "Malaysia" },
  { _id: "MV", name: "Maldives" },
  { _id: "ML", name: "Mali" },
  { _id: "MT", name: "Malta" },
  { _id: "MH", name: "Marshall Island" },
  { _id: "MQ", name: "Martinique" },
  { _id: "MR", name: "Mauritania" },
  { _id: "MU", name: "Mauritius" },
  { _id: "YT", name: "Mayotte" },
  { _id: "MX", name: "Mexico" },
  { _id: "FM", name: "Micronesia " },
  { _id: "MD", name: "Moldova, Republic of" },
  { _id: "MC", name: "Monaco" },
  { _id: "MN", name: "Mongolia" },
  { _id: "MS", name: "Monserrat" },
  { _id: "ME", name: "Montenegro" },
  { _id: "MA", name: "Morocco" },
  { _id: "MZ", name: "Mozambique" },
  { _id: "MM", name: "Myanmar" },
  { _id: "NA", name: "Namibia" },
  { _id: "NR", name: "Nauru" },
  { _id: "NP", name: "Nepal" },
  { _id: "NL", name: "Netherlands" },
  { _id: "AN", name: "Netherlands Antilles" },
  { _id: "NC", name: "New Caledonia" },
  { _id: "NZ", name: "New Zealand" },
  { _id: "NI", name: "Nicaragua" },
  { _id: "NE", name: "Niger" },
  { _id: "NG", name: "Nigeria" },
  { _id: "NU", name: "Niue" },
  { _id: "NF", name: "Norfolk Island" },
  { _id: "MP", name: "Mariana Islands" },
  { _id: "NO", name: "Norway" },
  { _id: "OM", name: "Oman" },
  { _id: "PK", name: "Pakistan" },
  { _id: "PW", name: "Palau" },
  { _id: "PS", name: "Palestine" },
  { _id: "PA", name: "Panama" },
  { _id: "PG", name: "Papua New Guinea" },
  { _id: "PY", name: "Paraguay" },
  { _id: "CN", name: "Rep of China" },
  { _id: "PE", name: "Peru" },
  { _id: "PH", name: "Philippines" },
  { _id: "PN", name: "Pitcairn" },
  { _id: "PI", name: "PLATINUM" },
  { _id: "PL", name: "Poland" },
  { _id: "PT", name: "Portugal" },
  { _id: "PR", name: "Puerto Rico" },
  { _id: "QA", name: "Qatar" },
  { _id: "TW", name: "Rep of China(Taiwan)" },
  { _id: "RE", name: "Reunion" },
  { _id: "RO", name: "Romania" },
  { _id: "RU", name: "Russian Federation" },
  { _id: "RW", name: "Rwanda" },
  { _id: "BL", name: "Saint Barthelemy" },
  { _id: "KN", name: "Saint Kitts Nevis" },
  { _id: "LC", name: "Saint Lucia" },
  { _id: "SX", name: "Saint Maarten(Dutch)" },
  { _id: "MF", name: "Saint Martin(French)" },
  { _id: "WS", name: "Samoa" },
  { _id: "SM", name: "San Marino" },
  { _id: "ST", name: "Sao Tome Principe" },
  { _id: "SA", name: "Saudi Arabia" },
  { _id: "SN", name: "Senegal" },
  { _id: "RS", name: "Serbia, Republic of" },
  { _id: "SC", name: "Seychelles" },
  { _id: "SL", name: "Sierra Leone" },
  { _id: "XS", name: "Silver" },
  { _id: "SS", name: "SILVER" },
  { _id: "SG", name: "Singapore" },
  { _id: "SK", name: "Slovakia" },
  { _id: "SI", name: "Slovenia" },
  { _id: "SB", name: "Solomon Islands" },
  { _id: "SO", name: "Somalia" },
  { _id: "ZA", name: "South Africa" },
  { _id: "GS", name: "South Georgia " },
  { _id: "ES", name: "Spain" },
  { _id: "LK", name: "Sri Lanka" },
  { _id: "SH", name: "St. Helena" },
  { _id: "PM", name: "St Pierre & Miquelon" },
  { _id: "VC", name: "St Vincent Grenadine" },
  { _id: "QQ", name: "Stateless" },
  { _id: "SP", name: "Stateless Persons" },
  { _id: "SD", name: "Sudan" },
  { _id: "SR", name: "Suriname" },
  { _id: "SJ", name: "Svalbard Jan Mayen" },
  { _id: "SZ", name: "Swaziland" },
  { _id: "SE", name: "Sweden" },
  { _id: "CH", name: "Switzerland" },
  { _id: "SY", name: "Syrian Arab Republic" },
  { _id: "TJ", name: "Tajikistan" },
  { _id: "TZ", name: "Tanzania" },
  { _id: "TH", name: "Thailand" },
  { _id: "TL", name: "Timor-Leste" },
  { _id: "TG", name: "Togo" },
  { _id: "TK", name: "Tokelau" },
  { _id: "TO", name: "Tonga" },
  { _id: "TT", name: "Trinidad and Tobago" },
  { _id: "TN", name: "Tunisia" },
  { _id: "TR", name: "Turkey" },
  { _id: "TM", name: "Turkmenistan" },
  { _id: "TC", name: "Turks and Caicos " },
  { _id: "TV", name: "Tuvalu" },
  { _id: "UG", name: "Uganda" },
  { _id: "UA", name: "Ukraine" },
  { _id: "AE", name: "United Arab Emirates" },
  { _id: "GB", name: "United Kingdom of GB and NI" },
  { _id: "UM", name: "United StatesMinor" },
  { _id: "US", name: "United States" },
  { _id: "UY", name: "Uruguay" },
  { _id: "YU", name: "USE RS(SERBIA)" },
  { _id: "UZ", name: "Uzbekistan" },
  { _id: "VU", name: "Vanuatu" },
  { _id: "VE", name: "Venezuela" },
  { _id: "VN", name: "Vietnam" },
  { _id: "VI", name: "Virgin Islands, U.S." },
  { _id: "WF", name: "Wallis et Futuna" },
  { _id: "EH", name: "Western Sahara" },
  { _id: "XX", name: "Worldwide" },
  { _id: "YE", name: "Yemen" },
  { _id: "ZM", name: "Zambia" },
  { _id: "ZW", name: "Zimbabwe" },
];

export const BANKS = [
  {
    _id: "Abu Dhabi Commercial Bank",
    name: "Abu Dhabi Commercial Bank",
  },
  {
    _id: "Abu Dhabi Islamic Bank",
    name: "Abu Dhabi Islamic Bank",
  },
  {
    _id: "Ajman Bank",
    name: "Ajman Bank",
  },
  {
    _id: "Al Hilal Bank",
    name: "Al Hilal Bank",
  },
  {
    _id: "Al Maryah Community Bank",
    name: "Al Maryah Community Bank",
  },
  {
    _id: "Al Masraf Arab Bank for Investment & Foreign Trade",
    name: "Al Masraf Arab Bank for Investment & Foreign Trade",
  },
  {
    _id: "Bank of Sharjah",
    name: "Bank of Sharjah",
  },
  {
    _id: "Commercial Bank International",
    name: "Commercial Bank International",
  },
  {
    _id: "Commercial Bank of Dubai",
    name: "Commercial Bank of Dubai",
  },
  {
    _id: "Dubai Bank",
    name: "Dubai Bank",
  },
  {
    _id: "Dubai Finance Bank",
    name: "Dubai Finance Bank",
  },
  {
    _id: "Dubai Islamic Bank",
    name: "Dubai Islamic Bank",
  },
  {
    _id: "Emirates Investment Bank",
    name: "Emirates Investment Bank",
  },
  {
    _id: "Emirates Islamic",
    name: "Emirates Islamic",
  },
  {
    _id: "Emirates NBD",
    name: "Emirates NBD",
  },
  {
    _id: "First Abu Dhabi Bank",
    name: "First Abu Dhabi Bank",
  },
  {
    _id: "Invest Bank",
    name: "Invest Bank",
  },
  {
    _id: "Mashreq",
    name: "Mashreq",
  },
  {
    _id: "National Bank of Fujairah",
    name: "National Bank of Fujairah",
  },
  {
    _id: "National Bank of Ras Al-Khaimah PJSC (RAKBANK)",
    name: "National Bank of Ras Al-Khaimah PJSC (RAKBANK)",
  },
  {
    _id: "National Bank of Umm Al-Quwain",
    name: "National Bank of Umm Al-Quwain",
  },
  {
    _id: "Noor Bank",
    name: "Noor Bank",
  },
  {
    _id: "Sharjah Islamic Bank",
    name: "Sharjah Islamic Bank",
  },
  {
    _id: "United Arab Bank",
    name: "United Arab Bank",
  },
  {
    _id: "United Bank Ltd.",
    name: "United Bank Ltd.",
  },
  {
    _id: "Standard Chartered Bank",
    name: "Standard Chartered Bank",
  },
  {
    _id: "Saudi National Bank",
    name: "Saudi National Bank",
  },
  {
    _id: "Rafidain Bank",
    name: "Rafidain Bank",
  },
  {
    _id: "NatWest Markets Plc",
    name: "NatWest Markets Plc",
  },
  {
    _id: "National Bank of Oman",
    name: "National Bank of Oman",
  },
  {
    _id: "National Bank of Kuwait",
    name: "National Bank of Kuwait",
  },
  {
    _id: "National Bank of Bahrain",
    name: "National Bank of Bahrain",
  },
  {
    _id: "MCB Bank Limited",
    name: "MCB Bank Limited",
  },
  {
    _id: "KEB Hana Bank",
    name: "KEB Hana Bank",
  },
  {
    _id: "Janata Bank Limited",
    name: "Janata Bank Limited",
  },
  {
    _id: "Intesa Sanpaolo S.P.A",
    name: "Intesa Sanpaolo S.P.A",
  },
  {
    _id: "Industrial & Commercial Bank of China",
    name: "Industrial & Commercial Bank of China",
  },
  {
    _id: "HSBC Bank Middle East Limited",
    name: "HSBC Bank Middle East Limited",
  },
  {
    _id: "Habib Bank Ltd.",
    name: "Habib Bank Ltd.",
  },
  {
    _id: "Habib Bank A.G Zurich",
    name: "Habib Bank A.G Zurich",
  },
  {
    _id: "Gulf International Bank",
    name: "Gulf International Bank",
  },
  {
    _id: "El Nilein Bank",
    name: "El Nilein Bank",
  },
  {
    _id: "Doha Bank",
    name: "Doha Bank",
  },
  {
    _id: "Deutsche Bank AG",
    name: "Deutsche Bank AG",
  },
  {
    _id: "Credit Agricloe",
    name: "Credit Agricloe",
  },
  {
    _id: "CitiBank N. A.",
    name: "CitiBank N. A.",
  },
  {
    _id: "BOK International Bank",
    name: "BOK International Bank",
  },
  {
    _id: "BNP Paribas",
    name: "BNP Paribas",
  },
  {
    _id: "Barclays Bank PLC",
    name: "Barclays Bank PLC",
  },
  {
    _id: "Banque Misr",
    name: "Banque Misr",
  },
  {
    _id: "Banque Banorient France",
    name: "Banque Banorient France",
  },
  {
    _id: "Bank Saderat Iran",
    name: "Bank Saderat Iran",
  },
  {
    _id: "Bank of China Limited",
    name: "Bank of China Limited",
  },
  {
    _id: "Bank of Baroda",
    name: "Bank of Baroda",
  },
  {
    _id: "Bank Melli Iran",
    name: "Bank Melli Iran",
  },
  {
    _id: "Bank Al Falah Limited",
    name: "Bank Al Falah Limited",
  },
  {
    _id: "Arab Bank",
    name: "Arab Bank",
  },
  {
    _id: "Arab African International Bank",
    name: "Arab African International Bank",
  },
  {
    _id: "Al Khaliji",
    name: "Al Khaliji",
  },
  {
    _id: "Al Ahli Bank of Kuwait",
    name: "Al Ahli Bank of Kuwait",
  },
  {
    _id: "Agricultural Bank of China Ltd.",
    name: "Agricultural Bank of China Ltd.",
  },
];

export const DOTNET_CARD_STATUS = [
  { id: '1', description: "New Employee" },
  { id: '2', description: "Send to Compliance KP" },
  { id: '3', description: "Compliance KP Passed" },
  { id: '4', description: "Compliance KP Failed" },
  { id: '5', description: "Send To Bank" },
  { id: '6', description: "Compliance Bank Passed" },
  { id: '7', description: "Compliance Bank Failed" },
  { id: '8', description: "Export To CBF - NI" },
  { id: '9', description: "CBF Feedback Passed - NI" },
  { id: '10', description: "CBF Feedback Failed - NI" },
  { id: '11', description: "Export to Processor" },
  { id: '12', description: "Account Received" },
  { id: '13', description: "Account Creation Failed" },
  { id: '14', description: "Card Creation From Persou" },
  { id: '15', description: "File Send To Persou" },
  { id: '16', description: "Card Printed" },
  { id: '17', description: "Card Received From Persou" },
  { id: '18', description: "Card Received in H.O" },
  { id: '19', description: "Assigned to Executive" },
  { id: '20', description: "Person Not Available" },
  { id: '21', description: "Request for Location Change" },
  { id: '22', description: "Card Activation Request Send" },
  { id: '23', description: "Card Delivered" },
  { id: '24', description: "Card Activated" }
]
