

const ONBOARD_APPROVALS = {
    ON_GET_APPROVALS: 'ON_GET_APPROVALS',

    ON_APPROVE_REJECT: 'ON_APPROVE_REJECT',

    GET_ON_EMPLOYEERS_LIST: 'GET_ON_EMPLOYEERS_LIST',

    GET_ON_SINGLE_APPROVAL: 'GET_ON_SINGLE_APPROVAL',

    GET_ON_BRANCHES: 'GET_ON_BRANCHES',

    UPDATE_ONBOARD_APPROVAL_SOCKET: 'UPDATE_ONBOARD_APPROVAL_SOCKET',
};

export default ONBOARD_APPROVALS
