const CashoutConstant = {

    GET_CASHOUT_CLIENTS: 'GET_CASHOUT_CLIENTS',

    GET_CASHOUT_EMPLOYTEE: 'GET_CASHOUT_EMPLOYTEE',

    GET_CASHOUT_BALANCE: 'GET_CASHOUT_BALANCE',

    SEND_OTP: 'SEND_OTP',

    CASH_WITHDRAW: 'CASH_WITHDRAW'
};

export default CashoutConstant;
