import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

const CPopup = ({ message, element, onConfirm, onCancel, disabled }) => (
  
  <Popconfirm
    title={message}
    onConfirm={onConfirm}
    okButtonProps={{ disabled: disabled }}
    // onCancel={onCancel}
    icon={
      <QuestionCircleOutlined
        style={{
          color: "red",
        }}
      />
    }
  >
    {element}
  </Popconfirm>

);
export default CPopup;
