import USER from "../constants/User.constant";

const initialState = {
    users: [],
    loading: true,
    metaData: {},
    addLoading: false,
    updateLoading: false,
    toggleStatusLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case USER.USERS_GET_API:
            return { ...state,
                users: action.data || state.users,
                metaData: action.metaData || state.metaData,
                loading: action.loading
            };

        case USER.USER_ADD_API:
            return { ...state, addLoading: action.loading };

        case USER.USER_UPDATE_API:
            return { ...state, updateLoading: action.loading };

        case USER.USER_TOGGLE_STATUS:
            return { ...state, toggleStatusLoading: action.loading };

        default:
            return state;
    }
};
