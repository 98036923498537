import React, { useEffect } from "react";
import { getProfile } from "./store/actions/Auth.action";
import { useDispatch, useSelector } from "react-redux";
import { Auth, Root } from "./routing";
import { CLoading } from "./uiComponents";
import { createBrowserHistory } from "history";
import { connectionSocket } from "./utils/socket";
import { changeBranch, changeClient, getBranches, getClients, selectCountry } from "./store/actions/Common.action";
import { getValueIntoLocalStorage, setValueIntoHeaders } from "./utils/asyncStorage/Functions";
import { BRANCH, BRANCH_ID, CLIENT, CLIENT_ID } from "./utils/asyncStorage/Constants";
import { IsExchangeHouse, IsExchangeHouseBranch, IsExchangeHouseBranchUser, IsExchangeHouseUser } from "./utils/methods";
import { checkPendingApprovals } from "./store/actions/Approval.action";

let history = createBrowserHistory({ window });

function App(props) {
  const dispatch = useDispatch();

  const reduxState = useSelector(({ auth, common }) => {
    return {
      loading: auth.loading || auth.getProfileLoading,
      isLoggedIn: auth.isLoggedIn,
      user: auth.user,
      getClientsLoading: common.getClientsLoading,
      getBranchesLoading: common.getBranchesLoading,
    };
  });

  const { loading, isLoggedIn, user } = reduxState;

  const isExchangeHouse = IsExchangeHouse();
  const isExchangeHouseBranch = IsExchangeHouseBranch();
  const isExchangeHouseBranchUser = IsExchangeHouseBranchUser();
  const isExchangeHouseUser = IsExchangeHouseUser();

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const getClientCallBack = (data, isSelectedClient, isSelectedClientId) => {
    if (isSelectedClientId && isSelectedClient?._id) {
      dispatch(changeClient(isSelectedClient));
      // data?.find(itm => itm?._id === isSelectedClientId)
    }
  };

  const changeBranchCallback = (isSelectedClient, isSelectedClientId) => {
    let body = { kyc: "ON_BOARD_REQUEST_APPROVED", defaultCompany: true };
    dispatch(getClients(body, (data) => getClientCallBack(data, isSelectedClient, isSelectedClientId), false));
  };

  useEffect(() => {
    if (isLoggedIn) {
      connectionSocket();
      dispatch(selectCountry());
      // dispatch(checkPendingApprovals());
      (async () => {
        let isSelectedBranchId = await getValueIntoLocalStorage(BRANCH_ID);
        let isSelectedBranch = await getValueIntoLocalStorage(BRANCH);
        isSelectedBranch = isSelectedBranch ? JSON.parse(isSelectedBranch) : {};
        if (isSelectedBranchId) {
          setValueIntoHeaders(isSelectedBranchId, "exchangeHouseBranch");
        }
        let isSelectedClientId = await getValueIntoLocalStorage(CLIENT_ID);
        let isSelectedClient = await getValueIntoLocalStorage(CLIENT);
        isSelectedClient = isSelectedClient ? JSON.parse(isSelectedClient) : {};
        if (isSelectedBranch) {
          setValueIntoHeaders(isSelectedBranch, "exchangeHouseBranch");
        }
        if (user) {
          let body = { kyc: "ON_BOARD_REQUEST_APPROVED", defaultCompany: true };
          if (isExchangeHouse) {
            dispatch(getBranches(body, null, false));
            if (isSelectedBranch) {
              dispatch(changeBranch(isSelectedBranch, () => changeBranchCallback(isSelectedClient, isSelectedClientId)));
            }
          } else if (isExchangeHouseUser) {
            dispatch(getBranches(body, null, false));
            if (isSelectedBranch) {
              dispatch(changeBranch(isSelectedBranch, () => changeBranchCallback(isSelectedClient, isSelectedClientId)));
            }
          } else if (isExchangeHouseBranch) {
            dispatch(changeBranch(user));
            dispatch(getClients(body, (data) => getClientCallBack(data, isSelectedClient, isSelectedClientId), false));
          } else if (isExchangeHouseBranchUser) {
            dispatch(changeBranch(user?.exchangeHouseBranch));
            dispatch(getClients(body, (data) => getClientCallBack(data, isSelectedClient, isSelectedClientId), false));
          }
        }
      })();
    }
  }, [isLoggedIn, user]);

  const renderRoutingWithRole = (value) => {
    switch (value) {
      case true:
        return <Root {...props} />;
      case false:
        return <Auth />;
      default:
        return <CLoading />;
    }
  };

  return <div className="app-container responsive">{loading ? <CLoading /> : renderRoutingWithRole(isLoggedIn)}</div>;
}

const withRouter = (Child) => {
  return function withRouter(props) {
    return <Child {...props} history={history} />;
  };
};

export default withRouter(App);
