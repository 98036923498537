import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  formatter,
  IsExchangeHouseBranch,
  MappedElement,
  IsExchangeHouseUser,
  IsExchangeHouseBranchUser,
  IsExchangeHouse,
} from "../../utils/methods";
import { CButton, CImage } from "../../uiComponents";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/Auth.action";
import { BsWallet2 } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import { CompanyIcon } from "../../assets/svg";
import { RiLockPasswordFill } from "react-icons/ri";
import { Tag } from "antd";
// import { IsExchangeHouseBranchUser } from "./../../utils/methods";

const SideMenuItem = ({ title, path, activeClass, close, icon, pathName, activeStatus }) => {
  return (
    <li className="side-menu-list-item">
      <NavLink
        to={path}
        id={path}
        className={({ isActive }) => (path !== "/" ? (isActive ? "active" : activeClass) : pathName === "/" ? "active" : "")}
        onClick={close}
      >
        {icon ? <CImage url={icon} className="icon-class" /> : null}
        {title}
        {/* {activeStatus ? (
          <Tag className="activeTag" color="success">
            Pending
          </Tag>
        ) : null} */}
      </NavLink>
    </li>
  );
};

function SideMenu({ close, routes, user }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const logoutFunc = () => {
    dispatch(logout());
  };

  const renderMenu = (menus) => {
    if (menus) {
      return (
        <ul>
          <MappedElement
            data={menus}
            renderElement={(menu, menuIndex) => {
              const { hideInMenu = false } = menu;
              return !hideInMenu ? (
                <SideMenuItem
                  key={menuIndex}
                  close={close}
                  title={menu.title}
                  icon={menu.icon}
                  path={menu.path}
                  pathName={location?.pathname}
                  activeStatus={menu?.activeStatus}
                />
              ) : null;
            }}
          />
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="side-menu">
      <button className="side-menu-header-button" onClick={close}>
        <FiX />
      </button>
      <div className="side-menu-header">
        {/* <div className="logo" >
                <CImage url={require('../../assets/images/logo-white.png')} />
            </div>
            <CImage url={require('../../assets/images/logo-white.png')} /> */}
        <div className="company-name">
          <CImage url={require("../../assets/images/logo-white.png")} className="logo-white" />
          {/* <CImage url={companyIcon} /> */}
        </div>
      </div>
      <div className="side-menu-body">
        <div className="user-detail">
          <CompanyIcon />
          <h3>{user?.companyName || user?.name || ""}</h3>
          {user?.exchangeHouseId ? <h5>ExchangeHouse ID: {user?.exchangeHouseId}</h5> : null}
          {user?.exchangeHouseBranchId ? (
            <>
              <h5>Branch ID: {user?.exchangeHouseBranchId}</h5>{" "}
            </>
          ) : null}
          {(IsExchangeHouseBranch() || IsExchangeHouseBranchUser() || IsExchangeHouseUser()) && user?.exchangeHouse?.companyName && (
            <h5>Head Office : {user?.exchangeHouse?.companyName}</h5>
          )}
          {IsExchangeHouseBranchUser() && user?.exchangeHouseBranch?.companyName && <h5>Branch : {user?.exchangeHouseBranch?.companyName}</h5>}
          {IsExchangeHouseBranchUser() && user?.exchangeHouseBranch?.exchangeHouse?.companyName && (
            <h5>Head Office : {user?.exchangeHouseBranch?.exchangeHouse?.companyName}</h5>
          )}
          {/* <CImage
                        src={companyIcon}
                         /> */}
          {/* <div className="user-wallet" >
                        <CImage url={require('../../assets/images/wallet.png')} />
                        <p>{formatter(2543)}</p>
                    </div> */}
        </div>
        {renderMenu(routes)}
      </div>
      <div className="side-menu-footer">
        <a href="#" className="side-menu-footer-text side-menu-footer-link" onClick={() => logoutFunc()}>
          <CImage url={require("../../assets/images/arrowleftfooter.png")} className="sidemenuarrow" /> &nbsp; Logout from Dashboard
        </a>
        <p className="side-menu-footer-text">© 2022 All Rights Reserved</p>
      </div>
      <CImage className="side-menu-background-image" url={require("../../assets/images/side-menu-background.png")} />
    </div>
  );
}
export default React.memo(SideMenu);
