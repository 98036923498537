import * as Yup from "yup";

const scheme = Yup.object().shape({

  passportNumber: Yup.string()
    .required(`Please enter passport number`)
    .matches(/^[A-Za-z0-9]+$/, `Passport number should be alphanumeric only`)
    .max(20, `Passport number should not be more than 20 digits`),

  passportExpiryDate: Yup.date()
    .label("Expiry Date")
    .required("Please enter expiry date")
    .typeError("Please enter expiry Date"),

  passportIssueDate: Yup.date()
    .label("Issue Date")
    .required("Please enter issue date")
    .typeError("Please enter issue Date"),

  passportFile: Yup.mixed()
    .label("File")
    .required("Please upload document").default({})
    .test("len", "Please upload document", (val) => Object.keys(val)?.length),
});

export default scheme;