import { get, post } from "../../utils/apiMethods";
import APPROVALS from "../constants/Approval.constant";
import { _readableTextCap, handleError, handleSuccess, openNotification } from "../../utils/methods";
import { TOPIC } from "../../utils/constants";

export const clearApprovalList = () => (dispatch) => {
  dispatch({
    type: APPROVALS.GET_APPROVALS,
    loading: false,
    data: [],
    metaData: {},
  });
};

export const checkPendingApprovals = (values, CB) => (dispatch) => {
  get(`approvals/getPendingFlag`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: APPROVALS.CHECK_APPROVALS, data: data?.data });
      } else {
        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || error?.message || "Something went wrong!");
    });
};
export const getApprovals = (values, CB) => (dispatch) => {
  dispatch({ type: APPROVALS.GET_APPROVALS, loading: true });
  post(`approvals/getAll`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: APPROVALS.GET_APPROVALS, loading: false, data: data?.data?.entries, metaData: data?.data?.metaData });
      } else {
        handleError(data?.data?.message);
        dispatch({ type: APPROVALS.GET_APPROVALS, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || error?.message || "Something went wrong!");
      dispatch({ type: APPROVALS.GET_APPROVALS, loading: false });
    });
};

export const approveOrReject = (payload, CB) => async (dispatch) => {
  dispatch({ type: APPROVALS.APPROVE_REJECT, loading: true });
  post(`approvals/takeAction`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: APPROVALS.APPROVE_REJECT, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: APPROVALS.APPROVE_REJECT, loading: false });
        handleSuccess(data?.data?.message || "Successfully APPROVALS File Uploaded");
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: APPROVALS.APPROVE_REJECT, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getEmployeersList = (values, CB) => (dispatch) => {
  dispatch({ type: APPROVALS.GET_EMPLOYEERS_LIST, loading: true });
  post(`approvals/pendingApprovalClients`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: APPROVALS.GET_EMPLOYEERS_LIST, loading: false, data: data?.data });
      } else {
        handleError(data?.data?.message);
        dispatch({ type: APPROVALS.GET_EMPLOYEERS_LIST, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || error?.message || "Something went wrong!");
      dispatch({ type: APPROVALS.GET_EMPLOYEERS_LIST, loading: false });
    });
};

export const getSingleApproval = (payload) => async (dispatch) => {
  dispatch({ type: APPROVALS.GET_SINGLE_APPROVAL, loading: true });
  post(`approvals/get`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: APPROVALS.GET_SINGLE_APPROVAL,
          loading: false,
          data: data?.data,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: APPROVALS.GET_SINGLE_APPROVAL, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: APPROVALS.GET_SINGLE_APPROVAL, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getApprovalBranches = (payload, CB) => async (dispatch) => {
  dispatch({ type: APPROVALS.GET_BRANCHES, loading: true });
  post(`exchangeHouse/getBranches`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: APPROVALS.GET_BRANCHES,
          loading: false,
          metaData: data?.data?.metaData,
          data: data?.data?.entries,
        });
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: APPROVALS.GET_BRANCHES, loading: false, data: [], metaData: {} });
      }
    })
    .catch((error) => {
      dispatch({ type: APPROVALS.GET_BRANCHES, loading: false, data: [], metaData: {} });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const updateApproval = (response, allFilter = {}, includedTopics = []) => async (dispatch, getState) => {
  let statuses = allFilter?.approvalTrackingStatus;
  let data = response?.approval;
  let isNew = response?.isNew;
  let exchangeHouseBranch = allFilter?.exchangeHouseBranch;
  let client = allFilter?.client;
  const previousApprovals = getState()?.approval.approvals;
  const dispatchType = APPROVALS.UPDATE_APPROVAL_SOCKET;
  const approvalCheck = client ? client : exchangeHouseBranch;

  if (data?.topic !== TOPIC.ON_BOARD_REQUEST && data?.topic !== TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST) {
    if (approvalCheck && approvalCheck?.length && (approvalCheck === data?.client?._id || approvalCheck === data?.exchangeHouseBranch?._id)) {
      if (!isNew) {
        openNotification('Approval updated', `Approval Id: ${data?.approvalId} is ${_readableTextCap(data?.approvalTrackingStatus)} ${(data?.approvalTracking && data?.approvalTracking?.length && data?.approvalTracking[data?.approvalTracking?.length - 1]?.approvedBy) ? data?.approvalTracking[data?.approvalTracking?.length - 1]?.approvedBy?.companyName || data?.approvalTracking[data?.approvalTracking?.length - 1]?.approvedBy?.name : ''}`)
      }
      if (previousApprovals?.length && previousApprovals?.some(item => Number(item?.approvalId) === Number(data?.approvalId))) {
        const foundedIndex = previousApprovals?.findIndex((item) => item?.approvalId === data?.approvalId);
        if (foundedIndex !== -1) {
          let newArray = previousApprovals;
          if (statuses?.length) {
            if (statuses?.includes(data?.approvalTrackingStatus)) {
              newArray = [...newArray.slice(0, foundedIndex), data, ...newArray.slice(foundedIndex + 1)];
            } else {
              newArray = previousApprovals.filter(approval => approval?.approvalId !== data?.approvalId);
            }
          } else {
            newArray = [...newArray.slice(0, foundedIndex), data, ...newArray.slice(foundedIndex + 1)];
          }
          dispatch({ type: dispatchType, data: newArray });
        }

      } else {
        if (!statuses?.length || (statuses?.length && statuses?.includes(data?.approvalTrackingStatus))) {
          if (!includedTopics?.length || (includedTopics?.length && includedTopics?.includes(data?.topic))) {
            let newArray = [data, ...previousApprovals];
            if (newArray?.length > 10) {
              newArray = newArray.sort((a, b) => b?.approvalId - a?.approvalId);
              newArray.pop();
            }
            openNotification('New Approval created', `Approval Id: ${data?.approvalId} created by ${data?.createdBy?.user?.companyName || data?.createdBy?.user?.name} of ${_readableTextCap(data?.topic)}`)
            dispatch({ type: dispatchType, data: newArray });
          }
        }
      }
    }
  }
}