
import DashboardConstant from "../constants/Dashboard.constant";

const initialState = {
    data: [],
    metaData: {},
    loading: false,

    getReportLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case DashboardConstant.GET_ALL:
            return {
                ...state,
                data: action.data,
                metaData: action.metaData,
                loading: action.loading
            };

        case DashboardConstant.GET_REPORT:
            return {
                ...state,
                getReportLoading: action.loading,
            };


        default:
            return state;
    }
};
