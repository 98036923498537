import { lazy } from "react";

const Login  = lazy(() => import("./auth/login/Login"));
const Branch  = lazy(() => import("./auth/branch/Branch"));
const Dashboard  = lazy(() => import("./dashboard/Dashboard"));
const CentivCard  = lazy(() => import("./centivCard/CentivCard"));
const Salaries  = lazy(() => import("./salaries/Salaries"));
const CentivCardDetails  = lazy(() => import("./centivCard/CentivCardDetails"));
const Employer  = lazy(() => import("./employer/Employer"));
const DomesticEmployer  = lazy(() => import("./domesticEmployer/domesticEmployer"));
const Approval  = lazy(() => import("./approval/Approval"));
const OnBoardApproval  = lazy(() => import("./onBoardApproval/OnBoardApproval"));
const Employees  = lazy(() => import("./employees/Employees"));
const ManageEmployees  = lazy(() => import("./manageEmployees/ManageEmployees"));
const AddEmployee  = lazy(() => import("./employees/add/Add"));
const AddEmployer  = lazy(() => import("./employer/add/Add"));
const Employee  = lazy(() => import("./employees/Employees"));
const AllTransactions  = lazy(() => import("./employees/updatedDetails/AllTransactions"));
const FullView  = lazy(() => import("./employer/details/FullDetails"));
const EmployeeDetailsUpdated  = lazy(() => import("./employees/updatedDetails/Details"));
const ResetPassword  = lazy(() => import("./auth/resetPass/resetPassword"));
const Otp  = lazy(() => import("./auth/otp/Otp"));
const Forgetpassword  = lazy(() => import("./auth/forgetpassword/Forget"));
const TermsAndPolicies  = lazy(() => import("./auth/TermsAndPolicies"));
const User  = lazy(() => import("./users/User"));
const Branches  = lazy(() => import("./branches/Branches"));
const AddBranch  = lazy(() => import("./branches/add/Add"));
const BranchDetails  = lazy(() => import("./branches/details/Details"));
const Transactions  = lazy(() => import("./transactions/Transactions"));
const SalaryStatus  = lazy(() => import("./salaryStatus/SalaryStatus"));
const Cashout  = lazy(() => import("./cashout/Cashout"));
const Establishment  = lazy(() => import("./establishment/Establishment"));
const EmployeesSideBar  = lazy(() => import("./employees/EmployeesSideBar"));
const Profile  = lazy(() => import("./profile/Profile"));
const ManageUsers  = lazy(() => import("./manageUsers/manageUsers"));
const FindCardHolder  = lazy(() => import("./findCardHolder"));
const Remittances  = lazy(() => import("./remittances"));
const AuthTwoFactor  = lazy(() => import("./auth/authenticationTwoFactor/authTwoFactor"));

export {
  Login,
  AuthTwoFactor,
  Branch,
  SalaryStatus,
  Approval,
  Employer,
  ManageEmployees,
  AllTransactions,
  Salaries,
  Otp,
  FullView,
  Forgetpassword,
  Employee,
  DomesticEmployer,
  AddEmployer,
  Dashboard,
  ResetPassword,
  Employees,
  AddEmployee,
  EmployeeDetailsUpdated,
  TermsAndPolicies,
  User,
  Branches,
  Transactions,
  AddBranch,
  CentivCard,
  CentivCardDetails,
  BranchDetails,
  OnBoardApproval,
  Cashout,
  Establishment,
  EmployeesSideBar,
  Profile,
  ManageUsers,
  FindCardHolder,
  Remittances
};
