

const DashboardConstant = {

    GET_ALL: 'GET_ALL',

    GET_REPORT: 'GET_REPORT',

};

export default DashboardConstant;
