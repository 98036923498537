const CommonConstant = {

    TOGGLE_LEFT_DRAWER: "TOGGLE_LEFT_DRAWER",

    CURRENT_COUNTRY: "CURRENT_COUNTRY",

    GET_COUNTRIES: 'GET_COUNTRIES',

    GET_STATES: 'GET_STATES',

    GET_CLIENTS: 'GET_CLIENTS',

    SELECTED_CLIENT: 'SELECTED_CLIENT',

    GET_BRANCHES_COMMON: 'GET_BRANCHES_COMMON',

    SELECTED_BRANCH: 'SELECTED_BRANCH',

    DOWNLOAD_CSV: "DOWNLOAD_CSV",

    RESET_AUTH_CODE: "RESET_AUTH_CODE",

    SYNC_BRANCH_CLIENT: 'SYNC_BRANCH_CLIENT',

    REFRESH_TOKEN_TIMER: "REFRESH_TOKEN_TIMER",

};

export default CommonConstant;
