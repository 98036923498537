import auth from "./Auth.reducer";
import dashboard from "./Dashboard.reducer";
import employees from "./Employees.reducer";
import common from "./Common.reducer";
import user from "./User.reducer";
import salaries from "./Salaries.reducer";
import transactions from "./Transactions.reducer";
import requests from "./Requests.reducer";
import centivCard from "./CentivCard.reducer";
import branches from "./Branches.reducer";
import employer from "./Employer.reducer";
import approval from "./Approval.reducer"; 
import SalaryStatus from "./SalaryStatus.reducer"; 
import onboardApprovals from "./OnBoardApproval.reducer.js";
import cashout from "./Cashout.reducer";
import establishment from "./Establishment.reducer";
import profile from "./Profile.reducer";
import manageUsers from "./MangeUsers.reducer";
import domestic from "./Domestic.reducer";
import findCardHolder from "./FindCardHolder.reducer";
import remittances from "./Remittances.reducer";

export default {
  auth,
  dashboard,
  employees,
  approval,
  common,
  user,
  employer,
  SalaryStatus,
  salaries,
  transactions,
  requests,
  centivCard,
  branches,
  onboardApprovals,
  cashout,
  establishment,
  profile,
  manageUsers,
  domestic,
  findCardHolder,
  remittances
};
