import APPROVAL from "../constants/Approval.constant";

const initialState = {
  getLoading: false,
  approvals: [],
  approvalsMetaData: {},
  pendingApprovals: false,
  pendingOnboardApprovals: false,
  approveRejectLoading: false,

  employeersList: [],
  getEmployeersLoading: false,

  getSingleApprovalLoading: false,
  approval: {},

  branches: [],
  getBranchesLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case APPROVAL.GET_APPROVALS:
      return {
        ...state,
        getLoading: action.loading,
        approvals: action.data || state.approvals,
        approvalsMetaData: action.metaData || state.approvalsMetaData,
      };

    case APPROVAL.APPROVE_REJECT:
      return {
        ...state,
        approveRejectLoading: action.loading,
      };
    case APPROVAL.CHECK_APPROVALS:
      return {
        ...state,
        pendingApprovals: action.data?.pendingApprovals,
        pendingOnboardApprovals: action.data?.pendingOnboardApprovals,
      };

    case APPROVAL.GET_EMPLOYEERS_LIST:
      return {
        ...state,
        employeersList: action.data,
        getEmployeersLoading: action.loading,
      };

    case APPROVAL.GET_SINGLE_APPROVAL:
      return {
        ...state,
        getSingleApprovalLoading: action.loading,
        approval: action.data,
      };

    case APPROVAL.GET_BRANCHES:
      return {
        ...state,
        getBranchesLoading: action.loading,
        branches: action.data,
      };

    case APPROVAL.UPDATE_APPROVAL_SOCKET:
      return {
        ...state,
        approvals: action.data,
      };


    default:
      return state;
  }
};
