import CASHOUT from "../constants/Cashout.constant";

const initialState = {
    clients: [],
    getClientLoading: false,

    employee: {},
    getEmployeeLoading: false,

    getBalanceLoading: false,

    sendOTPLoading: false,

    cashWithdrawalLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case CASHOUT.GET_CASHOUT_CLIENTS:
            return {
                ...state,
                clients: action.data,
                getClientLoading: action.loading,
            };

        case CASHOUT.GET_CASHOUT_EMPLOYTEE:
            return {
                ...state,
                employee: action.data,
                getEmployeeLoading: action.loading,
            };

        case CASHOUT.GET_CASHOUT_BALANCE:
            return {
                ...state,
                getBalanceLoading: action.loading,
            };

        case CASHOUT.SEND_OTP:
            return {
                ...state,
                sendOTPLoading: action.loading,
            };

        case CASHOUT.CASH_WITHDRAW:
            return {
                ...state,
                cashWithdrawalLoading: action.loading,
            };

        default:
            return state;
    }
};
