import React from "react";
import { createRoot } from "react-dom/client";
import "antd/dist/reset.css";
import "./assets/scss/index.scss";
import 'react-image-crop/dist/ReactCrop.css'
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { interceptor } from "./utils/intercepter";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store";

interceptor();
console.log = () => {};
function Root() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
    <Root />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
