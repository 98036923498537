import ONBOARD_APPROVALS from "../constants/OnBoardApproval.constant";

const initialState = {
    getLoading: false,
    approvals: [],
    approvalsMetaData: {},

    approveRejectLoading: false,

    employeersList: [],
    getEmployeersLoading: false,

    getSingleApprovalLoading: false,
    approval: {},

    branches: [],
    getBranchesLoading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case ONBOARD_APPROVALS.ON_GET_APPROVALS:
            return {
                ...state,
                getLoading: action.loading,
                approvals: action.data || state.approvals,
                approvalsMetaData: action.metaData || state.approvalsMetaData
            };

        case ONBOARD_APPROVALS.ON_APPROVE_REJECT:
            return {
                ...state,
                approveRejectLoading: action.loading,
            };

        case ONBOARD_APPROVALS.GET_ON_EMPLOYEERS_LIST:
            return {
                ...state,
                employeersList: action.data,
                getEmployeersLoading: action.loading,
            };

        case ONBOARD_APPROVALS.GET_ON_SINGLE_APPROVAL:
            return {
                ...state,
                getSingleApprovalLoading: action.loading,
                approval: action.data,
            };

        case ONBOARD_APPROVALS.GET_ON_BRANCHES:
            return {
                ...state,
                getBranchesLoading: action.loading,
                branches: action.data,
            };

        case ONBOARD_APPROVALS.UPDATE_ONBOARD_APPROVAL_SOCKET:
            return {
                ...state,
                approvals: action.data,
            };

        default:
            return state;
    }
};
