import REQUESTS from "../constants/Requests.constant";

const initialState = {
  getLoading: false,
  requests: [],
  metaData: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUESTS.GET_REQUESTS:
      return {
        ...state,
        getLoading: action.loading,
        requests: action.data,
        metaData: action.metaData
      };

    default:
      return state;
  }
};
