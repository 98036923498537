import PROFILE from "../constants/Profile.constant";

const initialState = {
    enableTwoFactorLoading: false,
    qrCode: '',
    qrModal: false,
    verifyTotpLoading: false,
    authKey: '',
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case PROFILE.ENABLE_TWO_FACTOR:
            return {
                ...state,
                enableTwoFactorLoading: action.loading,
            };

        case PROFILE.QR_CODE:
            return {
                ...state,
                qrCode: action.data,
                qrModal: action.modal,
                authKey: action.authKey,
            };

        case PROFILE.VERIFY_TOTP:
            return {
                ...state,
                verifyTotpLoading: action.loading,
            };

        default:
            return state;
    }
};
