import MANAGE_USERS from "../constants/ManageUsers.constant";

const initialState = {
    clients: [],
    getClientLoading: false,

    employee: {},
    getEmployeeLoading: false,

    sendOTPLoading: false,

    activateCardLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case MANAGE_USERS.GET_MANAGE_USERS_CLIENTS:
            return {
                ...state,
                clients: action.data,
                getClientLoading: action.loading,
            };

        case MANAGE_USERS.GET_MANAGE_USERS_EMPLOYTEE:
            return {
                ...state,
                employee: action.data,
                getEmployeeLoading: action.loading,
            };

        case MANAGE_USERS.SEND_OTP:
            return {
                ...state,
                sendOTPLoading: action.loading,
            };

        case MANAGE_USERS.ACTIVATE_CARD:
            return {
                ...state,
                activateCardLoading: action.loading,
            };

        default:
            return state;
    }
};
