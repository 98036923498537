import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Menu, Select } from "antd";
import { CField, CImage } from "../../uiComponents";
import { useNavigate } from "react-router";
import { IoIosMenu } from "react-icons/io";
import { ImArrowLeft2 } from "react-icons/im";
import { changeBranch, changeClient, getClients, toggleLeftDrawer } from "../../store/actions/Common.action";
import { USER_TYPE } from "../../utils/constants";
import Details from "../../pages/approval/Details";
import { getBranchById } from "../../utils/methods";

function Navbar(props) {
  const {
    title = "",
    subTitle = "",
    subTitleToTitle,
    renderRight = () => null,
    canGoBack = false,
    navbarBg,
    canRenderCompany = false,
    canRenderBranch = false,
    showAjmanLogo = true,
    companyLoading = false,
    branchLoading = false,
    domesticClient = false,
    approvedClients = false,
    notIncludeDomestic = false,
    branchPlaceHolder = '',
    companyPlaceHolder = '',
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenNotificationsDrawer, setIsOpenNotificationsDrawer] = useState(false);
  const reduxState = useSelector(({ auth, common, branches }) => {
    return {
      user: auth.user,
      clients: common.clients,
      getClientsLoading: common.getClientsLoading,
      selectedClient: common.selectedClient,
      branches: common?.branches,
      getBranchesLoading: common?.getBranchesLoading,
      selectedBranch: common.selectedBranch,
    };
  });

  const { user } = reduxState;
  const toggleNotificationsDrawer = (val = false, obj = {}) => {
    setIsOpenNotificationsDrawer(val);
  };
  const renderTitle = () => {
    if (title) {
      return title;
    } else {
      return sayHi();
    }
  };
  const renderSubTitle = () => {
    if (subTitle) {
      return subTitle;
    } else {
      return subTitleToTitle ? sayHi() : "";
    }
  };

  const sayHi = () => {
    return "List of All Employees, you can update/upload excel or pdf file to update employees data";
    // return `Hi ${reduxState?.user?.name}!`
  };

  const menu = (obj) => {
    return (
      <Menu className="header-dropdown">
        <Menu.Item key="0" onClick={() => null}>
          Update
        </Menu.Item>
        <Menu.Item key="1"> Share with all students </Menu.Item>
        <Menu.Item key="2"> Share with whole class </Menu.Item>
        <Menu.Item key="3"> Share with... </Menu.Item>
      </Menu>
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  const renderGoBack = () => {
    return <ImArrowLeft2 />;
  };

  const onChangeClient = (_id) => {
    let data = reduxState?.clients;
    // user?.userType === USER_TYPE.EXCHANGE_HOUSE_BRANCH_USER
    //   ? user?.allowedClients || []

    let foundClient = data?.find((item) => item?._id === _id);
    dispatch(changeClient(foundClient || {}));
  };

  const onChangeBranch = (_id) => {
    dispatch(changeBranch(getBranchById(reduxState?.branches, _id) || {}, changeBranchCallback, !domesticClient));
  };

  const renderCustomOptions = (options) => {
    if (options && options.length) {
      return options.map((option, index) => (
        <Select.Option key={index} value={option?._id}>{`${option?.companyName} (${option?.isDefaultClientForBranch ? 'Branch' : 'Client'})`}</Select.Option>
      ));
    }
    return null;
  };

  const renderCompany = () => {
    return (
      <CField
        containerClass="company-drop"
        placeholder={companyPlaceHolder || "Company Name"}
        isLabel={false}
        value={reduxState?.selectedClient}
        disabled={!reduxState?.selectedBranch || reduxState?.getClientsLoading || reduxState?.getBranchesLoading || companyLoading}
        loading={reduxState?.getClientsLoading}
        data={
          // user?.userType === USER_TYPE.EXCHANGE_HOUSE_BRANCH_USER ? user?.allowedClients || [] : reduxState?.clients
          reduxState?.clients
        }
        fieldType="select"
        renderSelectedKey="companyName"
        customSelectOption={renderCustomOptions}
        onChange={onChangeClient}
      />
    );
  };

  const changeBranchCallback = (_id) => {
    // dispatch(changeClient(""));
    if (_id) {
      let payload = {
        defaultCompany: true,
        ...(domesticClient && { isDomesticClientForBranch: true, kyc: "ON_BOARD_REQUEST_APPROVED" }),
        ...(notIncludeDomestic && { notIncludeDomestic: true }),
        ...(approvedClients && { kyc: "ON_BOARD_REQUEST_APPROVED" }),
      }
      dispatch(getClients(payload));
    }
  };

  const renderBranch = () => {
    return (
      <CField
        containerClass="company-drop"
        placeholder={branchPlaceHolder || "Branch Name"}
        isLabel={false}
        value={reduxState?.selectedBranch}
        disabled={reduxState?.getBranchesLoading || reduxState?.getClientsLoading || branchLoading}
        loading={reduxState?.getBranchesLoading}
        data={reduxState?.branches}
        fieldType="select"
        renderSelectedKey="companyName"
        onChange={onChangeBranch}
      />
    );
  };

  return (
    <div>
      <div
        className="navbar"
        style={{
          background: navbarBg,
        }}
      >
        <button
          className="menu-button"
          onClick={() => dispatch(toggleLeftDrawer(true))}
        >
          <IoIosMenu />
        </button>
        {canGoBack && (
          <button className="back-button" onClick={goBack}>
            {renderGoBack()}
          </button>
        )}
        <div className="main-nav">
          <div className="navbar-left">
            <h1>{subTitleToTitle ? renderSubTitle() : renderTitle()}</h1>
            <p>{subTitleToTitle ? sayHi() : renderSubTitle()}</p>
          </div>
          {showAjmanLogo ? (
            <div className="navbar-right">
              {/* <Space  size="middle" onClick={() => toggleNotificationsDrawer(true)}>
                <Badge className="notificationIcon" count={5}>
                  <BsBell size={24} className="c-icon notification"/>

                </Badge>

              </Space> */}
              {/* <CImage
                url={require("../../assets/images/ajman-bank-logo.png")}
                className="ajmanBankLogo"
              /> */}
            </div>
          ) : null}
        </div>
      </div>

      <div className="d-flex justify-end flex-wrap">
        {(user?.userType === USER_TYPE?.EXCHANGE_HOUSE || user?.userType === USER_TYPE?.EXCHANGE_HOUSE_USER)
          ? canRenderBranch
            ? renderBranch()
            : null
          : null}
        {canRenderCompany ? renderCompany() : null}
      </div>
      <div className="d-flex gap-20 w-100 justify-end">
        {renderRight() ? (
          <div className="navbar-right">{renderRight()}</div>
        ) : null}
      </div>
      <Drawer
        title={`Notificatios`}
        placement="right"
        className="custom-size"
        destroyOnClose={true}
        onClose={() => !reduxState?.actionLoading && toggleNotificationsDrawer()}
        open={isOpenNotificationsDrawer}
      >
        <Details
          onClose={toggleNotificationsDrawer}
          // selectedData={selectedData}
          // get={get}
          loading={reduxState?.actionLoading}
        // takeAction={takeAction}
        />
      </Drawer>
    </div>

  );
}
export default React.memo(Navbar);
