import React from 'react';
import { Spin } from 'antd';

const CLoading = ({ className = '', position = 'fixed', size = 'large', loading = true }) => {
    return loading ? (
        <div className={`loading ${className}`} style={{ position: position }}>
            <Spin size={size} style={{display: 'flex', alignItems: 'center'}} />
        </div>
    ) : null;
};

export default CLoading
