import EMPLOYER from "../constants/Employer.constant";

const initialState = {
    getLoading: false,
    employer: [],
    employersMetaData: {},

    addEmployerLoading: false,

    syncLoading: false,

    toggleAclLoading: false,

    dashboard: [],
    getDashoardLoading: false,
    downloadListLoading: false,
    addEstalishmentsLoading: false,

    changePasswordLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case EMPLOYER.GET_EMPLOYER:
            return {
                ...state,
                getLoading: action.loading,
                employer: action.data || state.employer,
                employersMetaData: action.metaData || state.employersMetaData
            };

        case EMPLOYER.ADD_EMPLOYER:
            return {
                ...state,
                addEmployerLoading: action.loading,
            };

        case EMPLOYER.SYNC_EMPLOYEERS:
            return {
                ...state,
                syncLoading: action.loading,
            };
        case EMPLOYER.DOWNLOAD_LIST:
            return {
                ...state,

                downloadListLoading: action.loading,
            };
        case EMPLOYER.TOGGLE_CLIENT_ACL:
            return {
                ...state,
                toggleAclLoading: action.loading,
            };

        case EMPLOYER.GET_EMPLOYER_DASHOARD:
            return {
                ...state,
                dashboard: action.data,
                getDashoardLoading: action.loading
            };

        case EMPLOYER.ADD_ESHTABLISHMENTS:
            return {
                ...state,
                addEstalishmentsLoading: action.loading,
            };

        case EMPLOYER.CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordLoading: action.loading,
            };

        default:
            return state;
    }
};
