import BRANCHES from "../constants/Branches.constant";

const initialState = {
    getLoading: false,
    branches: [],
    metaData: {},

    addLoading: false,
    updateLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case BRANCHES.GET_BRANCHES:
            return {
                ...state,
                getLoading: action.loading,
                branches:  action.data || state.branches,
                metaData: action.metaData
            };

        case BRANCHES.ADD_BRANCH:
            return { ...state, addLoading: action.loading };

        case BRANCHES.UPDATE_BRANCH:
            return { ...state, updateLoading: action.loading };

        default:
            return state;
    }

};
