import DOMESTIC from "../constants/Domestic.constant";

const initialState = {
    getLoading: false,
    approval: [],

    approveRejectLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case DOMESTIC.GET_DOMESTIC_APPROVAL:
            return {
                ...state,
                getLoading: action.loading,
                approval: action.data,
            };

        case DOMESTIC.APPROVE_REJECT:
            return {
                ...state,
                approveRejectLoading: action.loading,
            };

        default:
            return state;
    }
};
