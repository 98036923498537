const APPROVALS = {
  GET_APPROVALS: "GET_APPROVALS",
  CHECK_APPROVALS: "CHECK_APPROVALS",

  APPROVE_REJECT: "APPROVE_REJECT",

  GET_EMPLOYEERS_LIST: "GET_EMPLOYEERS_LIST",

  GET_SINGLE_APPROVAL: "GET_SINGLE_APPROVAL",

  GET_BRANCHES: "GET_BRANCHES",

  UPDATE_APPROVAL_SOCKET: 'UPDATE_APPROVAL_SOCKET',
};

export default APPROVALS;