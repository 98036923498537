const ManageUsersConstant = {

    GET_MANAGE_USERS_CLIENTS: 'GET_MANAGE_USER_CLIENTS',

    GET_MANAGE_USERS_EMPLOYTEE: 'GET_MANAGE_USER_EMPLOYTEE',

    SEND_OTP: 'SEND_OTP',

    ACTIVATE_CARD: 'ACTIVATE_CARD',

};

export default ManageUsersConstant;